<template>
  <v-card
    flat
    outlined
    color="transparent"
    class="pa-0 ma-0"
    style="background-color: white"
  >
    <v-card-title class="pa-0 d-flex rem flex-row flex-nowrap">
      <div style="width: 30px; height: 30px">
        <v-badge
          offset-x="8"
          offset-y="15"
          bottom
          color="transparent"
          v-if="schema"
        >
          <template #badge>
            <v-icon
              size="15"
              v-if="iconBadge"
              :color="iconBadge.color"
            >{{ iconBadge.icon }}
            </v-icon>
          </template>
          <v-avatar
            :color="schema.style.color"
            size="30"
          >
            <v-icon color="white">{{ schema.icon }}</v-icon>
          </v-avatar>
        </v-badge>
      </div>

      <v-card-title class="font-weight-bold mt-0 pt-0 pb-0 title-preview">{{ schema ? schema.entityName : '—' }} № {{
          detail.properties.element_number ? detail.properties.element_number : '—'
        }}
      </v-card-title>
    </v-card-title>
    <v-card-subtitle
      v-if="detail.properties.updated_at"
      class="mt-1 pa-0"
    >Дата последнего изменения {{ timestampToDate(detail.properties.updated_at) }}
    </v-card-subtitle>
    <v-row
      no-gutters
      dense
      class="fill-height flex-column"
    >
      <v-col>
        <div class="d-flex">
          <FancyBox
            v-if="image || defaultImage"
            :options="{
           Carousel: {
           infinite: false
          },
           Thumbs: {
           showOnStart: false
          }
        }"
          >
            <a
              data-fancybox="gallery"
              :data-src="image ? getFullImageUrl(image.path, image.file_name) : defaultImage.path"
              :data-caption="image ? image.description : defaultImage.description"
            >
              <v-img
                v-if="image || defaultImage"
                max-height="70"
                max-width="150"
                min-width="150"
                min-height="150"
                contain
                lazy-src="@/assets/gray.png"
                class="pa-0 ma-0 pointer"
                :src="image ? getThumbnailUrl(image.path, image.file_name) : defaultImage.path"
              ></v-img>
            </a>
          </FancyBox>
          <div
            v-else
            class="grey rounded align-center justify-center d-flex mr-1"
            style="height: 50px; width: 50px"
          >
            <v-icon color="white">mdi-image-off</v-icon>
          </div>
          <div class="align-self-center">
            <div
              v-for="(item, index) in showAttributes"
              v-if="attributes[item.name] && showAttributes.length && index < 5"
              :key="item.id"
              class="ml-0"
              :style="image || defaultImage ? 'max-width: 115px' : 'max-width: 200px'"
              :class="(index === showAttributes.length-1) ? 'mb-3' : ''"
            >
              <v-card-subtitle class="pa-0">{{ clearUnderscore(item.label) }}: {{
                  attributes[item.name] || '—'
                }}
              </v-card-subtitle>
              <v-divider v-if="index + 1 !== showAttributes.length"/>
            </div>
            <div
              class="ml-2"
              v-if="!showAttributes.length && !attributes.diametr_sm"
            >
              {{ $t('message.hereNothingShow') }}
              <v-icon
                :color="schema.style.color || 'green'"
                small
              >
                mdi-emoticon-sad-outline
              </v-icon>
            </div>
          </div>
        </div>
      </v-col>

      <v-col
        :md="5"
        class="ml-0 pt-1 d-flex flex-wrap align-baseline"
        style="gap: 5px"
      >
        <BaseBtn
          v-for="(tab, index) in tabs"
          v-if="tab.show"
          :key="index"
          @click="handleOpenDialog(tab)"
          :color="tab.color"
          color-icon="white"
          :icon="tab.icon"
          class="btns"
          height="50"
          size="18"
        />
        <BaseBtn
          @click="activeEditGeometry"
          color="#7bbbed"
          :disabled="detail.properties.state === 2"
          color-icon="white"
          icon="mdi-draw"
          class="btns"
          height="50"
          size="18"
        />
        <BaseBtn
          color="error"
          @click="isDeleteDialog = true"
          v-if="isCanDelete"
          icon="mdi-delete-variant"
          :disabled="detail.properties.state === 2"
          :loading="isLoading"
          class="btns"
          height="50"
          size="18"
        />
        <BaseBtn
          color="error"
          @click="isDataBaseDelete = true"
          v-if="canDeleteFromBase"
          icon="mdi-delete-outline"
          :loading="isLoading"
          class="btns"
          height="50"
          size="18"
        />
      </v-col>
      <v-col
        class="mt-2"
        v-if="detail.properties.state !== 2 && !isCreateMode"
      >
        <BaseBtn
          icon="mdi-content-copy"
          title="Копировать"
          is-type-icon
          small
          color="primary"
          width="unset"
          @click="handleCopyObject"
        />
        <div class="d-flex flex-row align-center pa-0">
          <v-checkbox
            hide-details
            class="ma-0 pa-0"
            v-model="isCopyValuesAttr"
            label="Копировать значения аттрибутов"
          ></v-checkbox>
        </div>
        <div class="d-flex flex-row align-center pa-0">
          <p class="ma-0">Смещение: в{{ $t('map.' + selectSide) }}</p>
          <BaseBtn
            is-type-icon
            icon="mdi-cog"
            @click="isChangeSideDialog = true"
          />
        </div>
      </v-col>
      <BaseDialog
        max-width="80vw"
        text="Направление копирования"
        is-confirm-btn
        @confirm="isChangeSideDialog = false"
        text-confirm="Подтвердить"
        :value="isChangeSideDialog"
      >
        <template #content>
          <div class="d-flex flex-column align-center">
            <BaseBtn
              size="30"
              is-type-icon
              icon="mdi-arrow-up-bold-box-outline"
              :color="selectSide === 'top' ? 'primary' : 'grey'"
              @click="selectSide = 'top'"
            />
            <div
              class="d-flex flex-row justify-space-between pt-5 pb-5"
              style="width: 100%"
            >
              <BaseBtn
                size="30"
                is-type-icon
                icon="mdi-arrow-left-bold-box-outline"
                :color="selectSide === 'left' ? 'primary' : 'grey'"
                @click="selectSide = 'left'"
              />
              <BaseBtn
                size="30"
                is-type-icon
                icon="mdi-image-filter-center-focus-strong"
                :color="selectSide === 'center' ? 'primary' : 'grey'"
                @click="selectSide = 'center'"
              />
              <BaseBtn
                size="30"
                is-type-icon
                icon="mdi-arrow-right-bold-box-outline"
                :color="selectSide === 'right' ? 'primary' : 'grey'"
                @click="selectSide = 'right'"
              />
            </div>
            <BaseBtn
              size="30"
              is-type-icon
              icon="mdi-arrow-down-bold-box-outline"
              :color="selectSide === 'bottom' ? 'primary' : 'grey'"
              @click="selectSide = 'bottom'"
            />
          </div>
        </template>
      </BaseDialog>
    </v-row>
    <MapObjectDetail
      :tab-window="tabWindow"
      :schema="schema"
      :value="detail"
      :isDialog="isDialog"
      @dialog="handleClose"
      @save="saveObject"
      @deleteDialog="isDeleteDialog = true"
      @dataBaseDeleteDialog="isDataBaseDelete = true"
      @restore="handleRestore"
      @close="isDialog = false"
      @geometry="activeEditGeometry"
      @handleChangeFiles="handleChangeFiles"
      @saveAfterImageOpen="saveAfterImageOpen"
      :loading="isLoading"
      :passport-id="passportId"
    />
    <BaseDialog
      @confirm="handleDeleteObject(0)"
      @input="isDeleteDialog = $event"
      v-model="isDeleteDialog"
      :text="$t('message.deleteObject')"
      role="delete"
      is-cancel-btn
      is-confirm-btn
      :max-width="350"
    />
    <!--  оставил как наработку в светлое будущее  <BaseDialog
          @confirm="deleteWithoutSaving"
          @input="isCloseWithoutSaveDialog = $event"
          v-model="isCloseWithoutSaveDialog"
          text="Если не сохранить созданный объект, то он будет удален, удалить?"
          role="delete"
          is-cancel-btn
          is-confirm-btn
          :max-width="350"
        />-->
    <BaseDeleteConfirm
      v-if="detail && !!detail.properties"
      :id="Number(detail.properties.id)"
      :isDialog="isDataBaseDelete"
      entity-text="объект"
      @closeDialog="isDataBaseDelete = false"
      @delete="deleteFromBase"
    />
  </v-card>
</template>

<script>
import BaseBtn from '@/components/base/UI/BaseBtn'
import '@/plugins/vuetify'
import BaseDialog from '@/components/base/BaseDialog'
import detail from '@/components/mixins/map/info/detail'
import BaseDeleteConfirm from '@/components/base/BaseDeleteConfirm'
import objectValidation from '@/components/mixins/validation/object'
import userInteraction from '@/components/mixins/userInteraction'
import formatters from '@/components/mixins/formatters'
import MapObjectDetail from '@/components/views/mobile/map/detail/mapInfo/MapObjectDetail'
import report from '@/components/views/account/map/detail/mapPassportDetail/reportTable/report'
import getPath from '@/components/mixins/getPath'
import FancyBox from '@/components/base/FancyBox'
import previewObject from '@/components/mixins/commonForLayouts/previewObject'
import { isOffline } from '@/components/utils/mobile/common'
import offline from '@/components/mixins/mobile/offline'
import { getNewObjects, getSystemPhotos } from '@/components/utils/mobile/filesystemInteraction'
import isOnline from '@/components/mixins/mobile/isOnline'
import { App } from '@capacitor/app'

export default {
  name: 'PreviewObject',
  components: { MapObjectDetail, BaseBtn, BaseDialog, BaseDeleteConfirm, FancyBox },
  mixins: [detail, objectValidation, userInteraction, formatters, report, getPath, previewObject, offline, isOnline],
  data () {
    return {
      defaultImage: null
    }
  },
  computed: {
    showAttributes () {// фильтр без значений и показ. в табл.
      const result = []
      const showAttrs = this.objectAttributes.filter(item => item.show_in_table)
      showAttrs.forEach((item) => {
        if (result.length <= 5 && !!this.attributes[item.name]) {
          result.push(item)
        }
      })
      return result //показываем первые 5 заполненных(!!) атрибутов
    },
    isCanDelete () {
      return (isOffline()) ||
        (this.isCanInteractionObject && this.detail.properties.state === 1)
    },
    isCreateMode () {
      return this.$store.getters['map/isCreateMode']
    }
  },
  methods: {
    async saveAfterImageOpen () {
      if (await this.validate()) {
        if (this.isOnline) {
          await this.save()
        } else {
          await this.handleSaveOffline('properties', true)
        }
        this.$emit('saveAfterImageOpen', this.detail)
      }
    },
    async setDefaultImage () {
      const photos = await getSystemPhotos(this.value?.properties.passport_id, this.value?.properties.id, this.value.geometry.coordinates)
      if (photos?.length) {
        this.defaultImage = photos[0].image
      }
    },
    deleteWithoutSaving () {
      this.$emit('delete')
    },
    handleClose () {
      /* относится к наработке в светлое будущее  if (!this.detail.properties.id) {
         this.isCloseWithoutSaveDialog = true
       } else {*/
      this.isDialog = false
      this.$emit('popup:close')
      /*}*/
    },
    async handleSave () {
      if (await this.validate()) {
        if (this.isOnline) {
          await this.save()
          this.$emit('save', { detail: this.detail, type: this.type })
        } else {
          await this.handleSaveOffline()
        }
      }
      return true
    },
    async handleDeleteObject (hard) {
      if (this.isOnline) {
        await this.handleDelete(hard)
      } else {
        await this.handleDeleteOffline(hard)
      }
    },
    async getPopupButtons () {
      const findGroup = this.objectAttributes.find((item) => item.eavType.id === 14)
      const hintEdit = findGroup ? findGroup.label : this.$t('base.edit')
      return this.tabs = [
        {
          title: this.isCanInteractionObject ? this.$t('constructor.editAttrs') : this.$t('passport.infoObject'),
          hint: hintEdit,
          icon: this.isCanInteractionObject ? 'mdi-pencil' : 'mdi-information-outline',
          show: true,
          color: 'info'
        },
        {
          title: this.$t('base.images'),
          hint: this.$t('base.images'),
          icon: 'mdi-image-area',
          show: this.detail.properties.state !== 2,
          color: '#7bbbed'
        },
        {
          title: this.$t('base.docs'),
          hint: this.$t('base.docs'),
          icon: 'mdi-file-document-multiple',
          show: !(await isOffline()) && this.detail.properties.state !== 2,
          color: '#7bbbed'
        },
        {
          title: this.$t('base.historyLog'),
          hint: this.$t('base.historyLog'),
          icon: 'mdi-history',
          show: !(await isOffline()),
          color: '#7bbbed'
        }
      ]
    }
  },
  async mounted () {
    App.addListener('backButton', () => {
      if (this.isDialog) {
        this.handleClose()
      }
    })
    this.$nextTick(async () => {
      if (await isOffline()) {
        await this.setDefaultImage()
        this.fileNew = (await getNewObjects(this.detail.properties.passport_id))?.find((item) => item?.properties?.id === this.detail.properties.id)
      }
    })
  },
  async beforeDestroy () {
    await App.removeAllListeners()
  }
}
</script>

<style>
  .leaflet-container a.leaflet-popup-close-button {
    top: 10px;
    right: 10px;
    font-size: 26px;
  }
</style>

<style scoped>
  .btns.v-btn:not(.v-btn--round).v-size--x-small {
    height: 28px !important;
    margin-left: 0 !important;
  }
  .title-preview {
    word-break: break-word;
    line-height: 1.5rem;
    font-size: 14px !important;
  }
  .checkbox {
    min-width: 130px;
  }
  .checkbox /deep/ .v-label {
    font-size: 8.75px !important;
    text-transform: uppercase !important;
  }
  .checkbox /deep/ .v-application--is-ltr .v-input--selection-controls__input {
    margin-right: 0;
  }
</style>
