import { required } from 'vuelidate/lib/validators'
import { validCoordinates } from '@/components/utils/customValidators'

export default {
  validations: {
    detail: {
      properties: {
        cadastr_number: {
          //required
        },
        object_name: {
          //required
        },
        length: {
          //required
        },
        width: {
          //required
        },
        area: {
          //required
        },
        territorial_affiliation: {
          //required
        },
        land_function: {
          //required
        },
        executing_organization: {
          //required
        },
        owner: {
          //required
        },
        classification_code: {
          //required
        },
        classification_code_detail: {
          //required
        },
        using_mode: {
          //required
        }
      },
      /*geometry: {
        type: {
          required
        },
        coordinates: {
          validCoordinates
        }
      }*/
    }
  }
}
