<template>
  <DetailView
    v-model="detail"
    :disabled="disabled"
    :attributes="attributes"
    :is-small-height="isSmallHeight"
    class-content="height-content-edit-mobile"
    classForm="form-mobile"
  />
</template>

<script>
import DetailView
  from '@/components/views/account/passport/detail/views/passportDashboard/passportObjectDetail/DetailView'


export default {
  name: 'MapObjectEdit',
  components: { DetailView },
  props: {
    value: Object,
    attributes: Array,
    disabled: Boolean,
    isSmallHeight: {
      type: Boolean,
      default: true
    },
    classForm: String
  },
  data () {
    return {
      detail: this.value
    }
  },
  watch: {
    value: {
      handler () {
        this.detail = this.value
      },
      deep: true
    }
  }
}
</script>

<style>
  /*note: поменять второе значение в calc, если изменится высота шапки map info*/
  .height-content-edit-mobile {
    height: calc(100vh - 240px) !important;
  }
</style>
