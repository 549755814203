<template>
  <BaseMapInfo
    :detail="detail"
    label=""
  >
    <template #tabs>
      <v-tabs
        v-model="tab"
        horizontal
        class="main-tabs"
        show-arrows
      >
        <v-tab
          v-for="item in items"
          :key="item.hint"
          v-show="item.show"
          @click="expand"
          v-if="item.hint === $t('base.geometry') ? isCanInteractionObject : true"
        >
          {{ item.hint }}
        </v-tab>
        <v-tabs-items :key="detail.properties.id" v-model="tab">
          <v-tab-item
            v-for="item in items"
            :key="item.hint"
            class="tab-item"
          >
            <component
              :attributes="item.attributes"
              v-model="detail"
              :is="item.content"
              model-name="object"
              :disabled="!isCanInteractionObject"
              :key="item.content === 'HistoryLog' ? keyHistoryLog : detail.properties.id"
              accept-types="application/pdf, .docx, .doc, .xls, .xlsx, .jpg, .jpeg, .png"
              :model-id="detail.properties.id"
              :prepend-inner-icon="item.prependInnerIcon"
              :is-loading="item.isLoading"
              @geometry="$emit('geometry')"
              :file-type="fileType"
              :next-step-start-after="nextStepStartAfter"
              :previous-step-end-after="previousStepEndAfter"
              :params-to-upload="paramsToUpload"
              :class="item.hint !== $t('base.edit') ? 'content-block' : ''"
              @parse:completed="handleParseComplete"
              @input="handleInput"
              @save="$emit('saveAfterImageOpen', detail)"
              nameObjectImg="objectImages"
              nameObjectDoc="objectFiles"
              with-description
              :geometry="detail.geometry"
            >
            </component>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </template>

    <template #dialog>
      <v-divider></v-divider>
      <BaseDialog
        @confirm="handleDelete(0)"
        v-model="deleteDialog"
        :text="$t('message.deleteObject')"
        role="delete"
        is-cancel-btn
        is-confirm-btn
        :max-width="350"
      />
      <BaseDeleteConfirm
        v-if="detail"
        :id="Number(detail.properties.id)"
        :isDialog="isDataBaseDelete"
        entity-text="объект"
        @closeDialog="isDataBaseDelete = false"
        @delete="handleDelete(1)"
      />
    </template>
  </BaseMapInfo>
</template>

<script>
import objectValidation from '@/components/mixins/validation/object'
import detail from '@/components/mixins/map/info/detail'
import MapObjectEdit from '@/components/views/account/map/detail/mapInfo/mapObjectInfo/MapObjectEdit'
import BaseMapInfo from '@/components/base/BaseMapInfo'
import BaseDeleteConfirm from '@/components/base/BaseDeleteConfirm'
import DetailGroup
  from '@/components/views/account/passport/detail/views/passportDashboard/passportObjectDetail/DetailGroup'
import BaseDialog from '@/components/base/BaseDialog'
import userInteraction from '@/components/mixins/userInteraction'
import mapObjectInfo from '@/components/mixins/commonForLayouts/mapObjectInfo'

export default {
  mixins: [objectValidation, detail, userInteraction, mapObjectInfo],
  name: 'MapObjectInfo',
  components: {
    MapObjectEdit,
    BaseMapInfo,
    BasePhoto: () => import('@/components/base/BasePhoto'),
    HistoryLog: () => import('@/components/views/account/passport/detail/views/passportDashboard/passportObjectDetail/HistoryLog/HistoryLog'),
    BaseDocuments: () => import('@/components/base/BaseDocuments'),
    BaseDeleteConfirm,
    DetailGroup,
    BaseDialog,
    ReportBurialCertificate: () => import('@/components/views/account/map/detail/mapInfo/mapObjectInfo/ReportBurialCertificate'),
    BaseImportPanel: () => import('@/components/base/baseImportPanel/BaseImportPanel')
  },
  mounted () {
    this.items = [
      ...this.groupItems,
      {
        hint: this.$t('base.edit'),
        content: 'MapObjectEdit',
        attributes: this.objectAttributes,
        show: true
      },
      {
        hint: this.$t('base.images'),
        content: 'BasePhoto',
        attributes: this.objectAttributes,
        show: true
      },
      {
        hint: this.$t('base.record'),
        content: 'ReportBurialCertificate',
        attributes: this.objectAttributes,
        show: this.detail.properties.eav_entity_id === 84
      },
      {
        hint: this.$t('base.docs'),
        content: 'BaseDocuments',
        attributes: this.objectAttributes,
        show: true
      },
      {
        hint: this.$t('base.historyLog'),
        content: 'HistoryLog',
        attributes: this.objectAttributes,
        show: true
      },
      {
        hint: this.$t('base.geometry'),
        content: 'BaseImportPanel',
        attributes: this.objectAttributes,
        show: this.getCurrentRole !== 'observer'
      }
    ]
    this.checkTab()
  }
}
</script>

<style scoped>
  /deep/ .tab-item {
    max-height: 50%;
    min-height: 75vh;
  }
  .map-detail .v-tab {
    padding: 5px 10px;
  }
  /deep/ .v-tabs-bar__content {
    overflow: auto;
  }
  .content-block {
    height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
</style>
