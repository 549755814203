<template>
  <v-dialog
    persistent
    :value="value"
    @input="$emit('input', $event)"
    transition="dialog-bottom-transition"
    :max-width="maxWidth"
    :content-class="getClass"
    @click:outside="!isCrossClose && handleClose"
    :fullscreen="isFullScreen"
    :overlay-opacity="overlayOpacity"
  >
    <v-card
      :id="idCard"
      :color="isTransparent ? 'transparent' : ''"
      flat
      @mousedown="startDrag"
      @touchstart="startDrag"
      @mouseup="endDrag"
      @touchend="endDrag"
      @mousemove="drag"
      @touchmove="drag"
    >
      <v-card-title
        v-if="withLabel"
        class="d-flex justify-lg-space-between fixed-bar"
        style="border: none"
        :class="classCardTitle"
      >
        <v-icon
          class="mr-1"
          v-if="prependIconLabel"
        >{{ prependIconLabel }}
        </v-icon>

        <div
          v-if="isSwipeLine"
          class="swipe-line"
        ></div>

        <div
          v-if="label"
          class="text-heading-4 font-weight-bold text-break"
        >
          <div>{{ label }}</div>
          <slot name="subtitle"></slot>
        </div>

        <v-icon v-if="appendIconLabel">{{ appendIconLabel }}</v-icon>

        <v-spacer v-if="isHeaderSpacer"></v-spacer>

        <slot name="customBtn"></slot>
      </v-card-title>
      <BaseBtn
        v-if="isCrossClose"
        is-type-icon
        icon="mdi-close"
        small
        style="position: absolute; top: 10px; right: 10px; z-index: 2"
        class="align-self-baseline"
        @click="handleClose"
        :color="isTransparent ? 'white' : ''"
      />
      <div :class="classContent">
        <slot name="content"></slot>
      </div>
      <v-card-actions class="justify-end pb-4">
        <BaseBtn
          color="primary"
          @click="handleConfirm"
          depressed
          :title="titleConfirm"
          class="mr-2"
          class-btn="text-capitalize"
          v-if="isConfirmBtn"
          :loading="isLoading"
          :disabled="isDisabledConfirm"
        />
        <BaseBtn
          depressed
          @click="handleClose"
          class-btn="grey lighten-3 text-capitalize primary--text"
          :title="titleCancel"
          v-if="isCancelBtn"
          :loading="isLoading"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseBtn from '@/components/base/UI/BaseBtn'
import keyup from '@/components/mixins/keyup'
import swipeDialog from '@/components/mixins/mobile/swipeDialog'

// ПРИМЕЧАНИЕ: для окна удаления указать role: 'delete' и itemId
export default {
  name: 'BaseDialog',
  components: { BaseBtn },
  mixins: [keyup, swipeDialog],
  props: {
    value: Boolean,
    isSwipeLine: Boolean,
    label: String,
    withLabel: Boolean,
    overlayOpacity: String,
    isTransparent: Boolean,
    maxWidth: {
      type: [Number, String],
      default: 1000
    },
    idCard: { type: String, default: 'swipe-dialog' }, // note: если нужен свайп, то idCard(разный для каждого окна!) обязателен!
    hideScroll: Boolean,
    isConfirmBtn: Boolean,
    isCancelBtn: Boolean,
    textConfirm: { default: 'Да', type: String },
    textCancel: { default: 'Нет', type: String },
    isCrossClose: Boolean,
    isFullScreen: Boolean,
    isHeaderSpacer: {
      type: Boolean,
      default: true
    },
    additionalClass: {
      default: '',
      type: String
    },
    appendIconLabel: String,
    prependIconLabel: String,
    isLoading: Boolean,
    role: {
      default: 'base',
      type: String
    },
    itemId: Number,
    isDisabledConfirm: Boolean,
    withoutSidesPadding: Boolean, // без боковых паддингов
    withoutBotPadding: Boolean, // убирает нижний паддинг у card-title
    isNestedDialog: Boolean // для вложенных диалоговых окон, чтобы отключить close на esc. Иначе оба закроются
  },
  computed: {
    getClass () {
      let classStyles = ''
      if (this.hideScroll) {
        classStyles += 'hideScroll '
      }
      if (this.isFullScreen) {
        classStyles += 'hideBorder '
      }
      return classStyles + ' ' + this.additionalClass
    },
    titleConfirm () {
      return this.role === 'base' ? this.textConfirm : this.$t('base.delete')
    },
    titleCancel () {
      return this.role === 'base' ? this.textCancel : this.$t('base.cancel')
    },
    classContent () {
      let result = ''
      if (!this.withoutSidesPadding) {
        result = 'pr-5 pl-5'
      }
      return result
    },
    classCardTitle () {
      let result = this.isTransparent ? 'transparent ' : ''
      if (this.withoutSidesPadding) {
        result = 'pl-4'
      }
      if (this.withoutBotPadding) {
        result += ' pb-0'
      }
      return result
    }
  },
  methods: {
    handleClose () {
      if (!this.isNestedDialog) {
        this.$emit('input', false)
      }
    },
    handleConfirm () {
      if (!this.isDisabledConfirm) {
        if (this.role === 'delete') {
          this.$emit('confirm', this.itemId)
          this.$emit('input', false)
        } else {
          this.$emit('confirm')
        }
      }
    }
  }
}
</script>

<style scoped>
  /deep/ .fixed-bar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
  }
  /deep/ .hideScroll {
    overflow-y: hidden !important;
  }
  /deep/ .hideBorder {
    border-radius: 0 !important;
  }
  .swipe-line {
    width: 50%;
    background: #c7c7c7;
    height: 5px;
    border-radius: 30px;
    margin-left: 24%;
  }
</style>
