import { baseUrl, filterBaseAttrs } from '@/config/common'
import { filterAttributes, flatenObject, iconObjectStatus, sortWithNull } from '@/components/utils/common'

export default {
  props: {
    value: Object,
    schema: Object,
    isChangeGeometry: Boolean,
    passportId: Number
  },
  data () {
    return {
      detail: this.value,
      baseUrl,
      isDialog: false,
      isDataBaseDelete: false,
      isDeleteDialog: false,
      isCloseWithoutSaveDialog: false, // вызывает диалог при закрытии без сохранения измененных данных (наработка)
      inCart: false,
      type: 'object',
      tabs: [],
      tabWindow: '',
      isCopyValuesAttr: true,
      selectSide: 'right',
      isChangeSideDialog: false,
      fileNew: null
    }
  },
  provide () {
    return {
      $v: this.$v
    }
  },
  watch: {
    value: {
      handler () {
        this.$set(this, 'detail', this.value)
      },
      deep: true
    }
  },
  computed: {
    canDeleteFromBase () {
      return this.currentRole === 'admin' || this.currentRole === 'manager'
    },
    controllerName () {
      return 'object'
    },
    currentRole () {
      return this.$store.getters['auth/getCurrentRole']
    },
    image () {
      return this.detail?.properties?.objectImages?.length && (this.isOnline || !this.fileNew) ?
        this.detail?.properties?.objectImages[0]?.image :
        null
    },
    objectAttributes () {
      const filteredBase = this.schema.baseAttributes.filter(item => filterBaseAttrs.includes(item.name))
      const objectAttributes = filterAttributes(filteredBase.concat(this.schema.eavAttributes), ['id', 'element_number', 'state'])
      return flatenObject(objectAttributes.sort(sortWithNull('order')), 'relatedAttributes')
    },
    attributes () {//note: Когда будет готово "показывать у тултипе", переделать
      const result = {}
      this.objectAttributes
        .forEach(relatedAttribute => {
          const valueByName = this.detail?.properties[relatedAttribute.name]
          if (relatedAttribute.optionCategoryId && valueByName) { //для связанных
            if (Array.isArray(valueByName)) { //если массив, то нужно сделать forEach
              result[relatedAttribute.name] = ''
              valueByName.forEach(item => result[relatedAttribute.name] += this.$store.getters['handbook/getCategoryValue'](relatedAttribute.optionCategoryId, item) + ' ')
            } else {
              result[relatedAttribute.name] = this.$store.getters['handbook/getCategoryValue'](relatedAttribute.optionCategoryId, valueByName)
            }
          } else { //для простых
            result[relatedAttribute.name] = !Array.isArray(valueByName) ? valueByName : null
          }
        })
      return result
    },
    iconBadge () {//todo: функция iconObjectStatus работает некорректно? Проверить.
      return iconObjectStatus(this.detail?.properties?.state, this.detail?.properties?.verification_status, this.detail?.properties?.origin)
    }
  },
  methods: {
    handleCopyObject () {
      const includesKeys = ['eav_entity_id', 'passport_id'] //поля, которые очищать не нужно
      const detail = {
        ...this.detail,
        properties: { ...this.detail.properties, id: null, element_number: null, objectImages: [], objectFiles: [] }
      }
      if (!this.isCopyValuesAttr) { //если не копируем значения аттрибутов
        for (let key in detail.properties) {
          if (!includesKeys.includes(key)) {
            detail.properties[key] = null //то, чистим
          }
        }
      }
      this.$emit('copy', { detail, side: this.selectSide })
    },
    handleOpenDialog (tab) {
      this.tabWindow = tab.hint
      this.isDialog = true
    },
    handleChangeFiles (detail) { // files and photos
      this.detail = detail
    },
    async saveObject () {
      this.$emit('isLoading', true)
      await this.handleSave()
      this.$emit('isLoading', false)
    },
    deleteFromBase () {
      this.isDialog = false
      if (this.inCart) {
        this.handleRemoveFromCart(this.detail, this.detail?.properties?.eav_entity_id)
      }
      this.handleDelete(1)
    },
    onClickCheckBox (active) {
      if (active) {
        this.handleAddToCart(this.detail, this.detail?.properties?.eav_entity_id)
      } else {
        this.handleRemoveFromCart(this.detail, this.detail?.properties?.eav_entity_id)
      }
    },
    activeEditGeometry () {
      this.isDialog = false
      this.$emit('geometry', { data: this.schema, elementNumber: this.detail.properties.element_number })
    }
  },
  async mounted () {
    this.getPopupButtons()
    this.inCart = !!this.detail && this.$store.getters['map/inCart'](this.detail?.properties?.eav_entity_id, this.detail)
    this.$nextTick(() => {
      //this.isDialog = this.isChangeGeometry || !this.detail.properties.id
      if (this.isChangeGeometry && this.detail.properties.id) {
        this.$store.dispatch('systemMessages/info', 'После изменения геометрии объект нужно сохранить', { root: true })
      }
    })

  }
}