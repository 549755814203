import { translate } from '@/messages'
export default [
  {
    text: translate('base.id'),
    align: 'start',
    value: 'id'
  },
  {
    text: translate('passport.type'),
    align: 'start',
    value: 'type'
  },
  {
    text: translate('passport.cadastr_number'),
    align: 'start',
    value: 'cadastr_number'
  },
  {
    text: translate('passport.objectName'),
    align: 'start',
    value: 'object_name'
  },
  {
    text: translate('passport.territorial_affiliation'),
    align: 'start',
    value: 'territorial_affiliation'
  },
  {
    text: translate('base.created_at'),
    align: 'start',
    value: 'created_at'
  },
  {
    text: translate('base.updated_at'),
    align: 'start',
    value: 'updated_at'
  }
]
