import validationErrors from '@/components/mixins/validationErrors'
import { changeModel } from '@/components/utils/mobile/filesystemInteraction'
import { isOffline } from '@/components/utils/mobile/common'

export default {
  mixins: [validationErrors],
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    async validate () {
      this.isLoading = true
      this.$v.$touch()
      await this.checkPending()
      let result = true
      if (this.$v.$anyError) {
        await this.$store.dispatch('systemMessages/error', 'Не удалось сохранить данные. Проверьте правильность заполнения полей')
        result = false
      }
      this.isLoading = false
      return result
    },
    async save () {
      if (!this.isLoading) {
        this.isLoading = true
        let response = await this.$store.dispatch('server/save', {
          url: this.controllerName + '/detail',
          data: { data: this.detail }
        })
        if (response) {
          this.detail = response
        }
        this.isLoading = false
      }
      return true
    },
    async handleSave () {
      if (await this.validate()) {
        await this.save()
        this.$emit('save', { detail: this.detail, type: this.type })
      }
      return true
    },
    async handleRestore () {
      this.$set(this.detail.properties, 'state', 1)
      if (await isOffline()) {
        await changeModel(this.detail)
      }
      await this.$store.dispatch('systemMessages/info', 'После восстановления объект нужно сохранить', { root: true })
    },
    /*async handleConfirm () {
      if (await this.validate()) {
        this.detail.properties.is_verified = true
        await this.save()
      }
      return true
    },*/
    async handleDelete (hard = null) { //soft - 0, from db - 1
      if (!this.isLoading) {
        if (this.detail.properties.id) {
          const data = hard || hard === 0 ? { hard } : null
          this.isLoading = true
          await this.$store.dispatch('server/delete', {
            url: this.controllerName + '/detail/' + this.detail.properties.id,
            data
          })
          this.$set(this.detail.properties, 'state', hard ? hard : 2)
          this.isLoading = false
        }
        this.$emit('delete', { detail: this.detail, type: this.type, hard })
      }
      return true
    },
    handleClose () {
      this.$emit('close', { type: this.type })
    }
  }
}
