<template>
  <div :key="keyFilterForm">
    <div :class="isFlexed ? 'flex-elements' : ''">
      <BaseSearchSelect
        v-if="typesPassport && typesPassport.length"
        v-model="model.type"
        emptyValue
        :class="isFlexed ? 'width50' : ''"
        name="type"
        :label="$t('passport.type')"
        item-text="label"
        item-value="value"
        :hide-details="hideDetails"
        :solo-inverted="soloInverted"
        append-icon="mdi-menu-down"
        :prop-data="typesPassport"
        @input="handlePassport($event, 'type')"
      />
      <div
        :class="isFlexed ? 'width50' : ''"
        v-if="$route.name !== 'map'"
      >
        <p v-if="$route.meta.layout !== 'MobileLayout'">{{ nameRegion }}</p>
        <BaseRegionSelect
          @input="handlePassport($event, 'regionId')"
          leafs-only
          :label="nameRegion"
        />
      </div>
    </div>
    <div :class="isFlexed ? 'flex-elements' : ''">
      <BuilderText
        v-model="model.q"
        name="q"
        data-type="string"
        :class="isFlexed ? 'width50' : ''"
        :solo-inverted="soloInverted"
        :hide-details="hideDetails"
        @input="handlePassport($event, 'q')"
        :label="$t('passport.objectName')"
      />
      <BuilderText
        v-model="model.cadastr_number"
        :class="isFlexed ? 'width50' : ''"
        name="cadastr_number"
        :solo-inverted="soloInverted"
        :hide-details="hideDetails"
        @input="handlePassport($event, 'cadastr_number')"
        label="Кадастровый номер"
      />
    </div>
  </div>
</template>

<script>
import BaseSearchSelect from '@/components/base/forms/BaseSearchSelect'
import filterCommon from '@/components/mixins/filterCommon'
import BuilderText from '@/components/base/forms/BuilderText'
import BaseRegionSelect from '@/components/base/forms/BaseRegionSelect'
//note: Вика - общее соображение по фильтрам. Возможно лучше, если все данные получаются в общем компоненте (здесь), чем когда каждый элемент фильтра их получает.
//обдумать на будущее
export default {
  name: 'FilterPassport',
  mixins: [filterCommon],
  components: { BaseSearchSelect, BuilderText, BaseRegionSelect },
  props: {
    hideDetails: Boolean,
    soloInverted: Boolean,
    changesFields: Object,
    isFlexed: Boolean
  },
  data () {
    return {
      model: {}
    }
  },
  methods: {
    handlePassport (data, name) {
      this.$set(this.model, name, data)
    }
  },
  watch: {
    model: {
      handler () {
        if (Object.keys(this.model).length === 0) {
          this.$emit('emptyField', true)
        } else {
          return this.$emit('change', this.model)
        }
      },
      deep: true
    },
    changesFields: {
      handler () {
        if (this.changesFields && Object.keys(this.changesFields).length !== 0) {
          this.model = this.changesFields
          return this.$emit('change', this.model)
        }
      },
      immediate: true
    }
  },
  computed: {
    nameRegion () {
      return this.$t('region.locality') + '/' + this.$t('region.subject')
    },
    typesPassport () {
      return this.$store.getters['serviceData/get']('passport.type')
    }
  }
}
</script>

<style scoped>
  .flex-elements {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
  .width50 {
    width: 50%;
  }
</style>

<style scoped>
  .flex-elements {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
  .width50 {
    width: 50%;
  }
</style>
