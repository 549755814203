<template>
  <BaseDialog
    :value="isDialog"
    @input="$emit('dialog', $event)"
    :label="labelObject"
    with-label
    hide-scroll
    max-width="100%"
    :subtitle="$t('base.updated_at') + ' ' + updatedTime"
  >
    <template #prependName>
      <v-avatar
        :color="schema.style.color"
        size="30"
        class="mr-3"
      >
        <v-icon color="white">{{ schema.icon }}</v-icon>
      </v-avatar>
    </template>
    <template #customBtn>
      <BaseBtn
        x-small
        color="primary"
        :title="$t('base.save')"
        @click="$emit('save')"
        v-if="currentRole !== 'auditor' && isCanInteractionObject"
        icon="mdi-content-save"
        :loading="loading"
      />
      <BaseBtn
        x-small
        color="error"
        :title="$t('base.delete')"
        @click="$emit('deleteDialog')"
        v-if="detail.properties.state === 1 && currentRole !== 'auditor' && isCanInteractionObject"
        icon="mdi-content-delete"
        :loading="loading"
      />
      <BaseBtn
        x-small
        color="secondary"
        :title="$t('base.restore')"
        @click="$emit('restore')"
        v-show="$can(null, 'editObject') && detail.properties.state === 2 && isCanInteractionObject"
        icon="mdi-restore"
        :loading="loading"
      />
      <BaseBtn
        x-small
        color="error"
        :title="$t('base.dataBaseDelete')"
        @click="$emit('dataBaseDeleteDialog')"
        v-if="isCanInteractionObject && (currentRole === 'admin' || currentRole === 'manager')"
        icon="mdi-delete-outline"
        :loading="loading"
      />
      <BaseBtn
        :title="$t('base.close')"
        x-small
        color="primary"
        @click="$emit('dialog', false)"
        :loading="loading"
      />
    </template>
    <template #content>
      <MapObjectInfo
        :tab-window="tabWindow"
        :value="detail"
        :schema="schema"
        :passport-id="passportId"
        @geometry="$emit('geometry')"
        @save="$emit('save')"
        @handleChangeFiles="$emit('handleChangeFiles', $event)"
        @saveAfterImageOpen="$emit('saveAfterImageOpen', $event)"
      />
    </template>
  </BaseDialog>
</template>

<script>
import BaseBtn from '@/components/base/UI/BaseBtn'
import BaseDialog from '@/components/base/BaseDialog'
import BaseDeleteConfirm from '@/components/base/BaseDeleteConfirm'
import MapObjectInfo from '@/components/views/account/map/detail/mapInfo/MapObjectInfo'
import detail from '@/components/mixins/map/info/detail'
import objectValidation from '@/components/mixins/validation/object'
import userInteraction from '@/components/mixins/userInteraction'
import formatters from '@/components/mixins/formatters'

export default {
  name: 'MapObjectDetail',
  components: { BaseBtn, BaseDialog, BaseDeleteConfirm, MapObjectInfo },
  mixins: [detail, objectValidation, userInteraction, formatters],
  props: {
    value: Object,
    schema: Object,
    isDialog: Boolean,
    loading: Boolean,
    passportId: Number,
    tabWindow: String // передавать hint
  },
  data () {
    return {
      detail: this.value
    }
  },
  watch: {
    value: {
      handler () {
        this.detail = this.value
      }, deep: true
    }
  },
  computed: {
    updatedTime () {
      return this.detail.properties.updated_at ? this.timestampToDate(this.detail.properties.updated_at) : '—'
    },
    labelObject () {
      return (this.schema ? this.schema.entityName : '—') + ' № ' + (this.detail.properties.element_number ? this.detail.properties.element_number : '—')
    }
  }
}
</script>
