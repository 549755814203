import { sortWithNull } from '@/components/utils/common'
import { filterBaseAttrs } from '@/config/common'
const OBJECT_TYPE = 'geometry'
const COMMAND_TYPE = 'parsing'
export default {
  props: {
    value: Object,
    schema: Object,
    passportId: Number,
    tabWindow: String // передавать hint
  },
  data () {
    return {
      deleteDialog: false,
      detail: this.value,
      tab: null,
      items: [],
      type: 'object',
      keyHistoryLog: 4,
      isDataBaseDelete: false,
      fileType: 'geojson',
      nextStepStartAfter: 1,
      previousStepEndAfter: 3,
      paramsToUpload: {
        commandType: COMMAND_TYPE,
        params: {
          entityId: this.value.properties.id,
          objectType: OBJECT_TYPE,
          parentId: this.passportId
        }
      }
    }
  },
  provide () {
    return {
      $v: this.$v
    }
  },
  computed: {
    inCart () {
      return this.$store.getters['map/inCart'](this.detail.properties.eav_entity_id, this.detail)
    },
    objectAttributes () {
      const filteredBase = this.schema.baseAttributes.filter(item => filterBaseAttrs.includes(item.name))
      const objectAttributes = filteredBase.concat(this.schema.eavAttributes)
      return objectAttributes.sort(sortWithNull('order'))
    },
    groupItems () {
      const filteredObjectAttr = this.objectAttributes.filter(item => item.eavType.id === 14)
      const result = []
      filteredObjectAttr.map((item) => {
        result.push({
          hint: item.label,
          attributes: item,
          content: 'DetailGroup',
          show: true
        })
      })
      return result
    },
    controllerName () {
      return 'object'
    },
    objectEntityName () {
      let name = ''
      if (this.detail.properties.eav_entity_id && this.detail.properties.id) {
        name = this.schema.entityName
      }
      return name
    },
    getCurrentRole () {
      return this.$store.getters['auth/getCurrentRole']
    }
  },
  methods: {
    async handleParseComplete () {
      const response = await this.$store.dispatch('server/get', {
        url: this.type + '/detail/' + this.detail.properties.id
      })
      this.$emit('save', { detail: response, type: this.type })
    },
    handleInput (event) {
      this.$set(this, 'detail', event)
      this.$emit('handleChangeFiles', this.detail)
    },
    handleAddToCart () {
      this.$store.commit('map/addCartItem', {
        schemaId: this.detail.properties.eav_entity_id,
        item: this.detail
      })
    },
    handleRemoveFromCart () {
      this.$store.commit('map/removeCartItem', {
        schemaId: this.detail.properties.eav_entity_id,
        item: this.detail
      })
    },
    expand () {
      this.$store.commit('map/changeCollapse', false)
    },
    checkTab () { //если есть в пропсах таб, то открыть его
      if (this.items.length) {
        const findTab = this.items.findIndex((item) => item.hint === this.tabWindow)
        this.tab = findTab >= 0 ? findTab : 0
      }
    }
  },
  watch: {
    value: {
      handler () {
        this.detail = this.value
      },
      deep: true
    },
    tab: {
      handler () {
        if (this.tab === 3) {
          this.keyHistoryLog += 1
        }
      }
    },
    tabWindow: {
      handler () {
        this.checkTab()
      }
    }
  },
}