<template>
  <div>
    <p class="pa-0 ma-0">{{ currentSchema.entityName }} №{{ data.properties.element_number }}</p>
  </div>
</template>

<script>

export default {
  name: 'MapTooltip',
  props: {
    data: Object,
    currentSchema: Object
  }
}
</script>