import { isOffline } from '@/components/utils/mobile/common'

export default {
  data () {
    return {
      isOnline: true
    }
  },
  async mounted () {
    this.isOnline = !await isOffline()
  }
}