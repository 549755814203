<template>
  <tr @click="$route.name === 'passport' ? detail(item.properties.id) : ''">
    <td
      v-if="selectable"
      width="30"
    >
      <v-checkbox
        class="mt-0 pt-0"
        dense
        v-model="localSelected"
        @click.passive.stop=""
        hide-details
      />
    </td>
    <td>{{item.properties.id}}</td>
    <td>{{formatValue('passport.type', item.properties.type)}}</td>
    <td>{{item.properties.cadastr_number}}</td>
    <td>{{item.properties.object_name}}</td>
    <td>{{item.properties.territorial_affiliation}}</td>
    <td>{{timestampToDate(item.properties.created_at)}}</td>
    <td>{{timestampToDate(item.properties.updated_at)}}</td>
  </tr>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import listItem from '@/components/mixins/listItem'

export default {
  mixins: [formatters, listItem],
  name: 'ListItem'
}
</script>

<style scoped>

</style>
