import L from 'leaflet'
import store from '@/store'
import { verificationScenarioByRole } from '@/components/utils/common'
import isMobileDevice from '@/components/utils/mobile/isMobileDevice'
import { readSystemFile, readSystemFilesInFolder } from '@/components/utils/mobile/filesystemInteraction'
import { isNeedOfflineMode } from '@/components/utils/mobile/common'

class BaseLayers {
  constructor (props) {
    this.on = props.on
    this.geoJson = {
      local: null,
      server: null
    }
    this.type = null
    this.parentId = props.parentId
  }

  async initLayers (map, info) {
    if (!this.geoJson.server || !this.geoJson.local) {
      const layer = L.geoJSON(null, {
        style: this._getStyle(),
        onEachFeature: this._handleEach()
      })
      this.geoJson.server = layer.addTo(map)
      this.geoJson.server.options.pmIgnore = true

      this.geoJson.local = layer.addTo(map)
      this.geoJson.local.options.pmIgnore = true
    }
    return true
  }

  async loadFromStore (map) {
    this.geoJson.server.addTo(map)
    this.geoJson.local.addTo(map)
    return true
  }

  updateServerLayer (oldLayer, data) {
    this.removeLayer(oldLayer)
    return this._addServerLayer(data, oldLayer)
  }

  updateDataServerLayer (layer, data) {
    const layers = this.geoJson.server[data.geometry.type][data.properties.eav_entity_id]._layers //все слои
    for (let _leaflet_id in layers) {
      if (_leaflet_id === String(layer._leaflet_id)) { //нужный слой
        layers[_leaflet_id].feature = data
      }
    }
  }

  bringToFrontAll () {
    this.geoJson.server.getLayers().forEach(layer => {
      layer.bringToFront()
    })
  }

  clearLayers () {
    this.geoJson.server.clearLayers()
  }

  async fillLayers (props) {
    if (props?.filter && _.size(props?.filter) > 1 && this.type === 'object') {
      const response = await store.dispatch('server/get', {
        url: '/object?filter[passport_id]=' + this.parentId + '&filter[verificationScenario]=' + props.scenario,
        params: { filter: props.filter }
      })
      this.updateLayers(response.models, props)
    }

    if (this._canFillLayers(props)) {
      if (!await isNeedOfflineMode()) {
        const response = await store.dispatch('server/get', {
          url: this._getUrl(props)
        })

        let iterationCount = 1;

        while (response.models.length < response.count) {
          const newResponse = await store.dispatch('server/get', {
            url: this._getUrl(props),
            params: {
              options: {
                page: ++iterationCount,
                itemsPerPage: 1000
              },
              filter: {
                verificationScenario: verificationScenarioByRole()
              }
            }
          });

          response.models.push(...newResponse.models)
        }

        if (response) {
          this._processLayersResponse(response, props.map)
        }
      } else if (isMobileDevice()) {
        let response = {
          models: []
        }
        if (this.type === 'passport') { //вроде отрисовка всех паспортов на карте
          const path = 'map/passports/'
          const files = await readSystemFilesInFolder(path)
          if (files) {
            await Promise.all(files?.files?.map(async (item) => {
              const file = await readSystemFile(path + item.name)
              response?.models?.push(JSON.parse(file))
            }))
          }
        } else if (this.type === 'object') { // здесь детали паспорта по типу count, models(Point, Polygon...)
          const path = 'map/passport/' + this.parentId + '/models/' + this.parentId
          const file = await readSystemFile(path)
          response = JSON.parse(file)

        }
        this._processLayersResponse(response, props.map)
      }
    }
    return true
  }

  updateLayers () {
  }

  _handleEach () {
    let self = this
    /**
     * @type {import('leaflet').GeoJSONOptions['onEachFeature']}
     * 
     * Функция отрисовки для каждого паспорта
     */
    return function (feature, layout) {
      layout.on({
        mouseover: (e) => self._handleLayerMouseover(e, feature, layout, self),
        mouseout: (e) => self._mouseout(e, feature, layout, self),
        ...self.on
      })
      const isSoftDelete = feature?.properties?.state === 2
      if (isSoftDelete && self.type === 'object') {
        layout.setStyle({ ...feature.properties.style, opacity: 0.5, fillOpacity: 0.5 })
      }
      if (feature?.properties?.style && feature?.properties?.verification_status !== 2) {
        layout.setStyle(feature.properties.style)
      }
      if (isMobileDevice() && feature.geometry.type === 'LineString') {
        layout.options.weight = 5
      }
    }
  }

  _handleLayerMouseover (e, feature, layout, self) {
    const eav_entity_id = e.target?.feature?.properties?.eav_entity_id
    const typeGeometry = e.target?.feature?.geometry?.type
    let isBlocked = false
    if (eav_entity_id && typeGeometry) {
      isBlocked = self?.geoJson?.server[typeGeometry][eav_entity_id]?.options?.isBlocked
    }
    if (isBlocked) return //если слой заблокирован, то не показывать тултип
    const currentSchema = self.schemas ? self.schemas[feature.properties?.eav_entity_id] : {}
    layout.bindTooltip(self._getTooltip(
      feature,
      currentSchema
    ))
    e.target.openTooltip()
  }

  _mouseout (e, feature, layout, self) {
    if (this.type === 'object') {
      layout?.unbindTooltip() // делаю unbind, чтобы не открылось на заблокированном слое
    }
  }

  _getTooltip (data) {
    return ''
  }

  _getStyle () {
  }

  _canFillLayers (props) {
    return !this.geoJson.server.getLayers().length
  }

  _getUrl (props) {
    return '/' + this.type
  }

  _processLayersResponse (response, map) {
    for (const model of response.models) {
      try {
        this.geoJson.server.addData(model)
      } catch (e) {
        console.log('invalid coordinates: ', e)
      }
    }
  }

  _removeServerLayer (layer) {
    this.geoJson.server.removeLayer(layer._leaflet_id)
  }

  _removeLocalLayer (layer) {
    this.geoJson.local.removeLayer(layer._leaflet_id)
  }

  removeLayer (layer) {
    if (layer.feature.properties.id) {
      this._removeServerLayer(layer)
    } else {
      this._removeLocalLayer(layer)
    }
  }

  addLocalLayer (data) {
    this.geoJson.local.addData(data)
    const layers = this.geoJson.local.getLayers()
    return layers[layers.length - 1]
  }

  _addServerLayer (data) {
    this.geoJson.server.addData(data)
    const layers = this.geoJson.server.getLayers()
    return layers[layers.length - 1]
  }

  updateSystemLayer () {
  }

  findByDetailId (data) {
    return this.geoJson.server.getLayers().find(layer => layer.feature.properties.id === data.properties.id)
  }
}

export default BaseLayers
