import BaseMapModel from '@/components/structures/map/models/model/baseModel'
import ObjectSelected from '@/components/structures/map/models/selected/objectSelected'
import ObjectLayers from '@/components/structures/map/models/layers/objectLayers'
import PassportObject from '@/components/structures/passportObject'
import Vue from 'vue'
import PreviewObject from '@/components/views/account/map/detail/common/PreviewObject'
import PreviewObjectMobile from '@/components/views/mobile/map/detail/common/PreviewObject'
import vuetify from '@/plugins/vuetify'
import { emitEvent } from '@/components/utils/map/common'
import store from '@/store'
import route from 'vue-router'
import axios from '@/plugins/axios'
import router from '@/router'
import isMobileDevice from '@/components/utils/mobile/isMobileDevice'
import { getRadiusPoint } from '@/components/utils/common'

class ObjectMapModel extends BaseMapModel {
  constructor (props) {
    super(props)
    this.parentId = props.parentId
    this.rotationOn = false
    this.selected = new ObjectSelected({ schemas: this.schemas })
    this.layers = new ObjectLayers({
      on: {
        click: this.handleClick.bind(this),
        'pm:update': this._drawUpdatedHandler.bind(this),
        'pm:dragenable': this._addMarkerAndEnableDrag.bind(this),
        'pm:drag': this._dragUpdatedHandler.bind(this), //при pm:dragend обновляется (стирается старый, создается новый) слой, настройки редактирования сбрасываются
        // 'pm:disable': this._emitEditDisabled.bind(this),
        'pm:rotateend': this._rotateUpdateHandler.bind(this),
        'pm:cut': this.cutUpdateHandler.bind(this)
      },
      edit: this.edit,
      editMap: false,
      schemas: this.schemas,
      parentId: this.parentId
    })

    this.type = 'object'
  }

  _rotateUpdateHandler (e) {
    this.selected.detail.geometry = e.layer.toGeoJSON().geometry
    this.selected.layer = e.layer
    this.rotationOn = true
    return true
  }

  async cutUpdateHandler (e) {
    this.selected.detail.geometry = e.layer.toGeoJSON().geometry
    this.selected.layer = e.layer
    this.selected.layer.feature.properties = e.originalLayer.feature.properties
    const model = this._getActiveModel(e.originalLayer) //получить модель
    const serverLayer = await model.updateLayer(e.originalLayer, this.selected.layer.feature) // новый обновленный слой
    await model._layerSelect(serverLayer, serverLayer.feature) //выбрать его
    e?.layer?._map?.fire('cut:finished')
    e?.layer?._map?.removeLayer(e.layer) //удаляю слой(созданный геоманом), поскольку геоман создает новый при вырезании и их два стало после updateLayer
    store.commit('map/lastSelected', serverLayer)
    return true
  }

  _drawUpdatedHandler (e) {
    if (this.rotationOn) { // проверка на то, что это событие вызывается не после rotateMode, после него летят ошибки
      this.rotationOn = false
      return true
    }
    this.selected.detail.geometry = e.layer.toGeoJSON().geometry
    this.selected.layer = e.layer
    const radius = getRadiusPoint(this.selected.layer._map._zoom)
    this.selected.layer.setStyle({ radius })
    return true
  }

  _emitEditDisabled (event) {
    // note: не понимаю зачем это. Срабатывает, когда ставим маркер.
    // event?.layer?._map?.fire('edit:disable', event) //note: генерим событие, которое слушаем через this.map.on
  }

  _getSelectedSchema () {
    return this.selected.detail ? this.schemas[this.selected.detail.properties.eav_entity_id] : null
  }

  _popupPreview (target, openDialog = false) {
    const self = this
    self.edit = false
    let isLoading = false
    const isMobile = isMobileDevice()
    const Preview = Vue.extend(isMobile ? PreviewObjectMobile : PreviewObject)
    const popupPreview = new Preview({
      vuetify,
      store, // решение, которое позволило не писать внутри в каждом компоненте импорта стора и прочих штук
      route,
      axios,
      router,
      propsData: {
        value: this.selected.detail || target.feature,
        schema: this._getSelectedSchema() || this.schemas[target.feature.properties.eav_entity_id],
        isChangeGeometry: this.selected?.layer?._leaflet_id !== target._leaflet_id,
        passportId: this.parentId
      }
    }).$mount()
    const minWidth = isMobile ? 250 : 430
    const maxWidth = isMobile ? 250 : 700
    target.bindPopup(popupPreview.$el, { interactive: true, minWidth: minWidth, maxWidth: maxWidth, closeButton: true })
    popupPreview.isDialog = openDialog

    popupPreview.$on('save', function (event) {
      self.edit = false
      emitEvent('save', event.detail) //решить проблему с закрытием и не развыделением объекта
    })

    popupPreview.$on('isLoading', function (event) {
      isLoading = event
      if (event) {
        emitEvent('isLoading:start', event)
      } else {
        emitEvent('isLoading:end', event)
      }
    })

    popupPreview.$on('copy', function (event) {
      emitEvent('copy', event)
    })

    popupPreview.$on('saveAfterImageOpen', function (event) {
      emitEvent('saveAfterImageOpen', event)
    })

    popupPreview.$on('delete', function (event) {
      emitEvent('delete', event)
    })

    popupPreview.$on('geometry', function (event) {
      emitEvent('object:geometry', event)
      self.edit = true
      target.closePopup()
    })

    popupPreview.$on('popup:close', function (event) {
      target.closePopup()
    })

    target.openPopup()
    target.getPopup().on('remove', function (event) { //работает на закрытие
      if (!self.edit) { //условие, на случай закрытия попапа сохраняющегося (в процессе) объекта или объекта в процессе ред. геометрии
        emitEvent('object:close')
        target.unbindPopup()
        popupPreview.$destroy()
      }
    })

    target.getPopup().on('add', function (event) { //работает на закрытие
      if (target.pm._layerDragEnabled || target.pm._enabled || self.edit) {
        target.closePopup()
      } else {
        self.edit = false
        popupPreview.isDialog = true
      }
    })
    return true
  }

  _drawCreatedHandler (geoJson, info) { //вызывается до сохранения
    const geoJsonLayer = this.layers.addLocalLayer({
      ...new PassportObject({ attributes: { ...geoJson.properties }, passportId: this.parentId }),
      geometry: geoJson.geometry
    })
    return this._layerSelect(geoJsonLayer, null, true)
  }

  _getOverlayControls () {
    let controls = []
    for (let schema in this.schemas) {
      let control = {
        label: this.schemas[schema].entityName,
        selectAllCheckbox: true,
        children: [],
        isShow: true
      }
      for (let type in this.layers.geoJson.server) {
        control.children.push({
          label: type,
          layer: this.layers.geoJson.server[type][schema]
        })
      }
      controls.push(control)
    }

    return controls
  }

  deleteLayer (info) {
    let model = this._getActiveModel(info.layer)
    model.clearChildren()
    if (info.detail.properties.id) {
      const isDelete = info.detail.properties.state === 2
      const verificationStatus = info.detail.properties.verification_status !== 3
      const isSoftDelete = verificationStatus && isDelete && info.detail.properties.origin
      model.selected.unselect(isSoftDelete)
      !isSoftDelete && model.layers.removeLayer(info.layer)
    } else {
      model.layers.removeLayer(info.layer)
    }
  }
}

export default ObjectMapModel
