export default {
  methods: {
    handleAddToCart (item, schemaId) {
      this.$store.commit('map/addCartItem', {
        schemaId,
        item
      })
    },
    handleRemoveFromCart (item, schemaId) {
      this.$store.commit('map/removeCartItem', {
        schemaId,
        item
      })
    }
  }
}