import BaseSelected from '@/components/structures/map/models/selected/baseSelected'
import BaseLayers from '@/components/structures/map/models/layers/baseLayers'
import { emitEvent } from '@/components/utils/map/common'
import store from '@/store'
import { isNeedOfflineMode, isOffline } from '@/components/utils/mobile/common'
import isMobileDevice from '@/components/utils/mobile/isMobileDevice'
import chroma from 'chroma-js'
import { getRadiusPoint } from '@/components/utils/common'

class BaseMapModel {
  constructor (props) {
    this.selected = new BaseSelected()
    this.type = null
    this.currentDragMarker = null //нужен для сохранения маркера и удаления после использования
    this.edit = false
    this.schemas = props && props.hasOwnProperty('schemas') ? props.schemas : null
    this.layers = new BaseLayers({
      on: {
        click: this.handleClick.bind(this),
        'pm:update': this._drawUpdatedHandler.bind(this)
      }
    })
  }

  async initLayers (map, info) {
    let model = this._getActiveModel(info.layer)
    await model.layers.initLayers(map, info)
    if (model.selected.children) {
      await model.selected.children.layers.initLayers(map, info)
    }
    return true
  }

  async loadLayersFromStore (map) {
    await this.layers.loadFromStore(map)
    if (this.selected.children) {
      await this.selected.children.loadLayersFromStore(map)
    }
    return true
  }

  async fillLayersEvented (props) {
    let model = this._getActiveModel(props.info.layer)
    emitEvent('layer:startLoading', model.type)
    await model._fillLayers(props)
    emitEvent('layer:endLoading', model.type)
    return true
  }

  async handleClick (e) {
    const eav_entity_id = e.target?.feature?.properties?.eav_entity_id
    const typeGeometry = e.target?.feature?.geometry?.type
    let isBlocked = false
    if (eav_entity_id && typeGeometry) {
      isBlocked = this?.layers?.geoJson?.server[typeGeometry][eav_entity_id]?.options?.isBlocked
    }
    if (!isBlocked) {
      !this.edit && emitEvent('layer:startLoading', true)
      //todo: рефактор (я (Кирилл) добавил id = main-map и для)
      const isRulerActive = store.getters['map/isRulerActive']
      if (!isRulerActive) {
        if (!this.selected.layer && !this.edit) {
          await this._layerSelect(e.target)
        } else if (this.selected.type === 'object' && !this.edit) {
          await this._layerSelect(e.target)
        }
        if (this.selected.type === 'object' && !this.edit) {
          this._popupPreview(e.target)
        }
      }
      !this.edit && emitEvent('layer:endLoading', false)
    }
    return true
  }

  _resetLastSelectedStyle (detail) {  //здесь идет затирание стилей у последнего выбранного объекта, если мы выбрали какой-то ещё. Чтобы все объекты не окрасились в этот цвет.
    const lastSelected = store.getters['map/lastSelected']
    if (lastSelected?._leaflet_id && lastSelected) {
      const typeGeometry = lastSelected.feature.geometry.type
      const eav_entity_id = lastSelected.feature.properties.eav_entity_id
      let serverLastLayers = []
      if (!lastSelected.feature.properties.id) { //для несохраненных объектов
        serverLastLayers = this.layers.geoJson.server[typeGeometry][eav_entity_id].getLayers()
          .filter((item) => item.feature.properties.id === null && item._leaflet_id === lastSelected?._leaflet_id)
      } else {
        const findLastLayerWithId = this.layers.geoJson.server[typeGeometry][eav_entity_id].getLayers()
          .find((item => item.feature.properties.id === lastSelected?.feature?.properties.id))
        serverLastLayers.push(findLastLayerWithId)
      }
      if (!serverLastLayers.length) return
      serverLastLayers.forEach((itemLayer) => {
        if (itemLayer && lastSelected?.feature?.properties.id !== detail?.properties.id || itemLayer && !itemLayer?.feature?.properties?.id) {
          const style = this.schemas[itemLayer.feature.properties.eav_entity_id]?.style
          const isSoftDelete = lastSelected?.feature?.properties.state === 2
          const opacity = isSoftDelete ? { opacity: 0.4, fillOpacity: 0.5 } : {
            opacity: 1,
            fillOpacity: lastSelected.feature.geometry.type === 'Point' ? 1 : 0.2
          }
          const findReportObject = store.getters['map/inCart'](detail.properties.eav_entity_id, lastSelected.feature)
          const reportStyle = findReportObject ? { color: chroma(this.schemas[lastSelected.feature.properties.eav_entity_id].style.color).brighten().hex() } : {}
          const usSavedStyles = !lastSelected.feature.properties.id ? { color: '#ffffff', fillColor: '#ff00e1' } : {}
          //вынужденно повторяю логику из _unselectLayer
          const radius = getRadiusPoint(itemLayer._map._zoom)
          itemLayer.setStyle({
            ...itemLayer.options,
            fillColor: lastSelected.defaultOptions.fillColor,
            color: lastSelected.defaultOptions.fillColor,
            ...style,
            fill: lastSelected.feature.geometry.type !== 'LineString',
            ...opacity,
            ...reportStyle,
            ...usSavedStyles,
            weight: lastSelected.feature.geometry.type === 'LineString' && isMobileDevice() ? 5 : 2,
            radius
          })
        }
      })
    }
  }

  _popupPreview (target, isOpen = false) {
    return true
  }

  findDetailByInfo (info, needToClear = true) {
    if (info && needToClear) {
      let model = this._getActiveModel(info.layer)
      model.clearChildren()
      if (model.selected.children) {
        model.selected.children.clearModel()
      }
      const isSoftDelete = info?.detail?.properties?.state === 2
      model.selected.unselect(isSoftDelete)
    }
    return this.getParentDetail()
  }

  findModelByLayer (layer) {
    return this._getActiveModel(layer)
  }

  getParentDetail () {
    let newModel = this._getParentModel()
    return newModel._getInfoData()
  }

  async findLayerByDetailId (data) {
    let model = this._getActiveModel(data.info.layer)
    if (model.selected.children) {
      model = model.selected.children
      model.selected.children?.clearChildren()
    }
    model.clearChildren()
    const layer = model.layers.findByDetailId(data.search.data)
    if (data.search.selected !== false) {
      await model._layerSelect(layer)
    }
    return layer
  }

  async updateLayer (oldLayer, data, ctx = null) {
    let model = ctx ? ctx : this._getActiveModel(oldLayer)
    model.clearChildren()
    const serverLayer = model.layers.updateServerLayer(oldLayer, data)
    if (await isNeedOfflineMode()) {
      await model.layers.updateSystemLayer(data) //обновление объектов в моделе
    }
    if (model.selected.children && model.selected.children.layers) {
      model.selected.children.layers.bringToFrontAll()
    }
    if (model.type === 'object') {
      model.selected.unselect()
    } else {
      await model._layerSelect(serverLayer, serverLayer.feature)
    }
    return serverLayer
  }

  async updateLayerAfterImageOpen (oldLayer, data, ctx = null) {
    let model = ctx ? ctx : this._getActiveModel(oldLayer)
    model.clearChildren()
    model.layers.updateDataServerLayer(oldLayer, data)
    if (await isNeedOfflineMode()) {
      await model.layers.updateSystemLayer(data) //обновление объектов в моделе
    }
    if (model.selected.children && model.selected.children.layers) {
      model.selected.children.layers.bringToFrontAll()
    }
  }

  async deleteLayer (info, data) {
    let model = this._getActiveModel(info.layer)
    if (data?.detail?.hard === 1 || info.type === 'passport') {
      model.clearChildren()
    }
    model.selected.unselect(data?.detail?.hard === 0)
    if (data && data?.detail?.detail?.properties.id) {//если есть id, т.е. не новый объект
      const isHardDelete = data?.detail?.hard
      const isDelete = info.detail.properties.state === 2
      const isSoftDelete = isDelete && info.detail.properties.origin
      const canDeleteOffline = (await isOffline()) ? info.detail.properties.verification_status !== 3 : true
      if (canDeleteOffline) {
        if (isHardDelete || !isSoftDelete) {
          model.layers.removeLayer(info.layer)
        }
      }
    } else {
      model.layers.removeLayer(info.layer)
    }
  }

  clearChildren () {
    if (this.selected.children) {
      this.selected.children.clearModel()
    }
  }

  clearModel () {
    try {
      this.layers.clearLayers()
      this.clearChildren()
    } catch (e) {
      console.log(e)
    }
  }

  toggleEdit (info, status) {
    let model = this._getActiveModel(info.layer)
    if (model.selected?.children?.edit) {
      model.selected.children.edit = status
    }
  }

  handleDrawCreated (props, info) {
    let model = this._getActiveModel(info.layer)
    model.edit = false
    return model._drawCreatedHandler.bind(model)(props)
  }

  overlayControls (info) {
    let model = this._getActiveModel(info.layer)
    return model._getOverlayControls()
  }

  async _fillLayers (props) {
    await this.layers.fillLayers(props)
    if (this.selected.children) {
      await this.selected.children._fillLayers(props)
    }
    return true
  }

  _getActiveModel (layer) {
    let activeModel = this
    if (layer && this.selected.layer && this.selected.layer._leaflet_id !== layer._leaflet_id && this.selected.children?._getActiveModel) {
      activeModel = this.selected.children._getActiveModel(layer)
    }
    return activeModel
  }

  _getParentModel () {
    let model = this
    if (this.selected.children && this.selected.children.selected.children) {
      model = this.selected.children._getParentModel()
    }
    return model
  }

  async _layerSelect (layer, detail = null, _isDrawFinish) {
    this._resetLastSelectedStyle(detail || layer.feature, this.selected || layer)
    this.selected.select(layer, this.type)
    const radius = getRadiusPoint(layer._map._zoom)
    if (detail) {
      this.selected.detail = detail
    } else if (!this.selected.detail || layer.feature.properties.id !== this.selected.detail.properties.id) {
      await this.selected.fillDetail()
    } else {
      this.selected.detail.geometry = layer.feature.geometry
    }
    await this._initChildren()
    emitEvent('layer:click', this._getInfoData())
    if (this.type === 'object' && _isDrawFinish) {
      setTimeout(() => {
        emitEvent('enableEditCustom', { ...this.selected })
      }, 0)
    }
    layer.setStyle({ radius })
    return true
  }

  _getInfoData () {
    return {
      type: this.selected.type,
      detail: this.selected.detail,
      layer: this.selected.layer,
      schema: this._getSelectedSchema(),
      schemas: this._getSchemas()
    }
  }

  _getSchemas () {
    return null
  }

  _getSelectedSchema () {
    return null
  }

  _initChildren () {
    return this.selected.initChildren(null)
  }

  _drawCreatedHandler (e) {
    return true
  }

  async _drawUpdatedHandler (e) {
    if (this.rotationOn) { // проверка на то, что это событие вызывается не после rotateMode, после него летят ошибки
      this.rotationOn = false
      return true
    }
    let layerGeoJson = e.layer.toGeoJSON()
    if (this.selected.detail) {
      layerGeoJson.properties = this.selected.detail.properties
    }
    this.selected.type === 'passport' && await store.dispatch('systemMessages/info', 'Необходимо сохранение после изменения геометрии')
    await this.updateLayer(e.layer, layerGeoJson, this)
  }

  async _dragUpdatedHandler (e) {
    this.selected.detail.geometry = e.layer.toGeoJSON().geometry
    this.selected.layer = e.layer
    return true
  }

  _addMarkerAndEnableDrag (e) {
    if (this.selected.layer) {
      // получаем центр
      let center = this.selected.layer.getCenter ? this.selected.layer.getCenter() : this.selected.layer.getLatLng()
      let defaultIcon = L.divIcon({
        iconSize: [18, 18],
        className: 'mdi mdi-drag-variant custom-drag-marker'
      })
      let isMobile = isMobileDevice()
      // Создаем маркер и добавляем его на карту
      let marker = isMobile ?
        L.marker({ lat: center.lat, lng: center.lng }, {
          draggable: true,
          icon: defaultIcon
        }) :
        L.marker(center, { draggable: true, icon: defaultIcon })

      marker.addTo(this.selected.layer._map)

      // Сохраняем текущий маркер
      this.currentDragMarker = marker

      // Проверяем и отключаем обработчик события перемещения для слоя
      if (this.selected.layer?.pm.layerDragEnabled()) {
        this.selected.layer.pm.disableLayerDrag()
      }

      // Добавляем обработчик события перемещения маркера
      marker.on('drag', async (event) => {
        // Обновляем координаты центральной точки при перемещении маркера
        let newCenter = event.target.getLatLng()

        // Вычисляем смещение между старым и новым центром
        const offset = {
          lat: newCenter.lat - center.lat,
          lng: newCenter.lng - center.lng
        }

        this._moveObjectByOffset(offset, newCenter)

        // Обновляем центр для следующего перемещения
        center = newCenter
        await this._dragUpdatedHandler(e)
      })
    }
    return true
  }

  _getBottomLeftCorner () {
    const map = this.selected.layer._map
    return map.containerPointToLatLng(L.point(0, map.getSize().y))
  }

  _moveObjectByOffset (offset, newCenter) {
// Обновляем координаты слоя на основе смещения
    if (this.selected.layer.setLatLng) {
      this.selected.layer.setLatLng(new L.LatLng(newCenter.lat, newCenter.lng))
    } else if (this.selected.layer.setLatLngs) {
      const currentLatLngs = this.selected.layer.getLatLngs()
      const isMultiPolygon = !!Array.isArray(currentLatLngs[0][0])
      if (isMultiPolygon) { //MultiPolygon
        const newLatLngs = currentLatLngs.map(polygons => polygons.map(latlngGroup => latlngGroup.map(latlng => L.latLng(latlng.lat + offset.lat, latlng.lng + offset.lng))))
        this.selected.layer.setLatLngs(newLatLngs)
      } else {
        const newLatLngs = Array.isArray(currentLatLngs[0])
          ? currentLatLngs.map(latlngGroup => latlngGroup.map(latlng => L.latLng(latlng.lat + offset.lat, latlng.lng + offset.lng)))
          : currentLatLngs.map(latlng => L.latLng(latlng.lat + offset.lat, latlng.lng + offset.lng))
        this.selected.layer.setLatLngs(newLatLngs)
      }
      this.selected.detail.geometry.coordinates = this.selected.layer.feature.geometry.coordinates
    } else {
      console.error('Координаты не обновились')
    }
  }

  _removeCurrentDragMarker () {
    if (this.currentDragMarker) {
      this.currentDragMarker.remove()
      this.currentDragMarker = null
    }
  }

  _getOverlayControls (info) {
    return {}
  }

  setEdit (status) {
    if (this.selected.children) {
      this.selected.children.edit = status
      this.selected.children.layers.edit = status
    }
    this.edit = status
    this.layers.edit = status
  }
}

export default BaseMapModel
