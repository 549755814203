import BaseMapModel from '@/components/structures/map/models/model/baseModel'
import ObjectMapModel from '@/components/structures/map/models/model/objectModel'
import PassportSelected from '@/components/structures/map/models/selected/passportSelected'
import PassportLayers from '@/components/structures/map/models/layers/passportLayers'
import Passport from '@/components/structures/passport'
import store from '@/store'

class PassportMapModel extends BaseMapModel {
  constructor (props) {
    super(props)
    this.selected = new PassportSelected()
    this.layers = new PassportLayers({
      on: {
        click: this.handleClick.bind(this),
        'pm:update': this._drawUpdatedHandler.bind(this),
        'pm:cut': this.cutUpdateHandler.bind(this)
      },
      parentId: props.parentId
    })
    this.type = 'passport'
  }

  async cutUpdateHandler (e) {
    this.selected.detail.geometry = e.layer.toGeoJSON().geometry
    this.selected.layer = e.layer
    this.selected.layer.feature.properties = e.originalLayer.feature.properties
    let layerGeoJson = e.layer.toGeoJSON()
    if (this.selected.detail) {
      layerGeoJson.properties = this.selected.detail.properties
    }
    await this.updateLayer(e.originalLayer, layerGeoJson, this)
    this.type === 'passport' && await store.dispatch('systemMessages/info', 'Необходимо сохранение после изменения геометрии')
    e?.layer?._map?.fire('cut:finished')
    e?.layer?._map?.removeLayer(e.layer)
    return true
  }

  async _initChildren () {
    return this.selected.initChildren(new ObjectMapModel({
      schemas: this._getSchemas(),
      parentId: this.selected.detail.properties.id
    }))
  }

  _getSchemas () {
    let schemas = {}
    if (this.selected.detail) {
      this.selected.detail.properties.passportEntities?.forEach(passportEntity => {
        schemas[passportEntity.eavEntity.id] = passportEntity.eavEntity
      })
    }
    return schemas
  }

  _drawCreatedHandler (e) {
    // создаем объект
    if (this.selected.detail) {
      return this.selected.children._drawCreatedHandler(e)
    } else { // создаем паспорт
      let model = new Passport({
        region_id: null
      })
      const geoJsonLayer = this.layers.addLocalLayer({ ...model, geometry: e.geometry })
      return this._layerSelect(geoJsonLayer)
    }
  }

  _getOverlayControls () {
    let controls = []
    if (this.selected.layer) {
      controls = this.selected.children._getOverlayControls()
    } else {
      controls.push({ label: 'Паспорт', layer: this.layers.geoJson.server })
    }
    return controls
  }
}

export default PassportMapModel
