<template>
  <v-card
    flat
    outlined
    color="transparent"
    class="pa-0 ma-0"
    style="background-color: white; width: 700px;"
  >
    <v-card-title class="pa-0 mb-4 d-flex rem flex-row flex-nowrap">
      <div style="width: 30px; height: 30px">
        <v-badge
          offset-x="8"
          offset-y="15"
          bottom
          color="transparent"
          v-if="schema"
        >
          <template #badge>
            <v-tooltip
              left
              v-if="iconBadge"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  size="15"
                  :color="iconBadge.color"
                >{{ iconBadge.icon }}
                </v-icon>
              </template>
              {{ iconBadge.tip }}
            </v-tooltip>
          </template>
          <v-avatar
            :color="schema.style.color"
            size="30"
          >
            <v-icon color="white">{{ schema.icon }}</v-icon>
          </v-avatar>
        </v-badge>
      </div>

      <v-card-title class="font-weight-bold mt-0 pt-0 pb-0 title-preview">{{ schema ? schema.entityName : '—' }} № {{
          detail.properties.element_number ? detail.properties.element_number : '—'
        }}
      </v-card-title>
    </v-card-title>
    <v-row
      no-gutters
      dense
      class="fill-height"
    >
      <v-col :cols="2">
        <FancyBox
          v-if="image"
          :options="{
           Carousel: {
           infinite: false
          },
           Thumbs: {
           showOnStart: false
          }
        }"
        >
          <a
            data-fancybox="gallery"
            :data-src="getFullImageUrl(image.path, image.file_name)"
            :data-caption="image.description"
          >
            <v-img
              v-if="image"
              max-height="70"
              max-width="100"
              min-width="70"
              min-height="100"
              contain
              lazy-src="@/assets/gray.png"
              class="pa-0 ma-0 pointer"
              :src="getThumbnailUrl(image.path, image.file_name)"
            ></v-img>
          </a>
        </FancyBox>
        <div
          v-else
          class="grey rounded align-center justify-center d-flex"
          style="height: 100px; width: 70px"
        >
          <v-icon color="white">mdi-image-off</v-icon>
        </div>
      </v-col>
      <v-col
        :md="5"
        class="ml-0"
      >
        <div
          v-if="attributes[item.name] && showAttributes.length"
          :key="item.id"
          v-for="(item, index) in showAttributes"
          class="ml-2"
        >
          <v-card-subtitle class="pa-0">{{ clearUnderscore(item.label) }}: {{
              attributes[item.name] || '—'
            }}
          </v-card-subtitle>
          <v-divider v-if="index + 1 !== showAttributes.length"/>
        </div>
        <v-card-title v-if="!showAttributes.length && !attributes.diametr_sm">
          {{ $t('message.hereNothingShow') }}
          <v-icon
            :color="schema.style.color || 'green'"
            large
          >
            mdi-emoticon-sad-outline
          </v-icon>
        </v-card-title>
      </v-col>
      <v-col
        :md="5"
        class="ml-0"
      >
        <BaseBtn
          v-for="(tab, index) in tabs"
          v-if="tab.show"
          :key="index"
          @click="handleOpenDialog(tab)"
          color="info"
          class-btn="ma-1"
          :icon="tab.icon"
          x-small
          :title="tab.title"
        />
        <BaseBtn
          x-small
          color="error"
          class="ma-1"
          :title="$t('base.delete')"
          @click="isDeleteDialog = true"
          v-if="isCanInteractionObject && detail.properties.state === 1"
          icon="mdi-delete-variant"
          :loading="isLoading"
        />
        <BaseBtn
          x-small
          color="error"
          class="ma-1"
          :title="$t('base.dataBaseDelete')"
          @click="isDataBaseDelete = true"
          v-if="canDeleteFromBase"
          icon="mdi-delete-outline"
          :loading="isLoading"
        />
        <v-btn
          target="_blank"
          :disabled="isLoading"
          :href="funeralReport"
          class="btn_link"
          color="primary"
          v-if="detail.properties.eav_entity_id === 84"
          x-small
        >
          {{ $t('map.reportBurial') }}
        </v-btn>
        <v-btn
          x-small
          v-if="detail.properties.eav_entity_id === 84"
          target="_blank"
          :disabled="isLoading"
          :href="monumentReport"
          color="primary"
          class="mt-1"
        >
          {{ $t('map.reportSetMonument') }}
        </v-btn>
        <v-checkbox
          class="checkbox ma-1 pa-0"
          label="Добавить в список"
          dense
          hide-details
          v-model="inCart"
          @change="onClickCheckBox"
          :disabled="!detail.properties.id"
        ></v-checkbox>
      </v-col>
      <v-col
        class="mt-2"
        v-if="detail.properties.state !== 2"
      >
        <BaseBtn
          icon="mdi-content-copy"
          title="Копировать"
          is-type-icon
          small
          color="primary"
          width="unset"
          @click="handleCopyObject"
        />
        <div class="d-flex flex-row align-center pa-0">
          <v-checkbox
            hide-details
            class="ma-0 pa-0"
            v-model="isCopyValuesAttr"
            label="Копировать значения аттрибутов"
          ></v-checkbox>
        </div>
        <div class="d-flex flex-row align-center pa-0">
          <p class="ma-0">Смещение: в{{ $t('map.' + selectSide) }}</p>
          <BaseBtn
            is-type-icon
            icon="mdi-cog"
            @click="isChangeSideDialog = true"
          />
        </div>
      </v-col>
      <BaseDialog
        max-width="80vw"
        text="Направление копирования"
        is-confirm-btn
        @confirm="isChangeSideDialog = false"
        text-confirm="Подтвердить"
        :value="isChangeSideDialog"
      >
        <template #content>
          <div class="d-flex flex-column align-center">
            <BaseBtn
              size="30"
              is-type-icon
              icon="mdi-arrow-up-bold-box-outline"
              :color="selectSide === 'top' ? 'primary' : 'grey'"
              @click="selectSide = 'top'"
            />
            <div
              class="d-flex flex-row justify-space-between pt-5 pb-5"
              style="width: 100%"
            >
              <BaseBtn
                size="30"
                is-type-icon
                icon="mdi-arrow-left-bold-box-outline"
                :color="selectSide === 'left' ? 'primary' : 'grey'"
                @click="selectSide = 'left'"
              />
              <BaseBtn
                size="30"
                is-type-icon
                icon="mdi-image-filter-center-focus-strong"
                :color="selectSide === 'center' ? 'primary' : 'grey'"
                @click="selectSide = 'center'"
              />
              <BaseBtn
                size="30"
                is-type-icon
                icon="mdi-arrow-right-bold-box-outline"
                :color="selectSide === 'right' ? 'primary' : 'grey'"
                @click="selectSide = 'right'"
              />
            </div>
            <BaseBtn
              size="30"
              is-type-icon
              icon="mdi-arrow-down-bold-box-outline"
              :color="selectSide === 'bottom' ? 'primary' : 'grey'"
              @click="selectSide = 'bottom'"
            />
          </div>
        </template>
      </BaseDialog>
    </v-row>
    <MapObjectDetail
      :tab-window="tabWindow"
      :schema="schema"
      :value="detail"
      :isDialog="isDialog"
      @dialog="isDialog = $event"
      @save="saveObject"
      @deleteDialog="isDeleteDialog = true"
      @dataBaseDeleteDialog="isDataBaseDelete = true"
      @restore="handleRestore"
      @close="isDialog = false"
      @geometry="activeEditGeometry"
      @handleChangeFiles="handleChangeFiles"
      @saveAfterImageOpen="saveAfterImageOpen"
      :loading="isLoading"
      :passport-id="passportId"
    />
    <BaseDialog
      @confirm="handleDelete(0)"
      @input="isDeleteDialog = $event"
      v-model="isDeleteDialog"
      :text="$t('message.deleteObject')"
      role="delete"
      is-cancel-btn
      is-confirm-btn
      :max-width="350"
    />
    <BaseDeleteConfirm
      v-if="detail && !!detail.properties"
      :id="Number(detail.properties.id)"
      :isDialog="isDataBaseDelete"
      entity-text="объект"
      @closeDialog="isDataBaseDelete = false"
      @delete="deleteFromBase"
    />
  </v-card>
</template>

<script>
import BaseBtn from '@/components/base/UI/BaseBtn'
import '@/plugins/vuetify'
import BaseDialog from '@/components/base/BaseDialog'
import detail from '@/components/mixins/map/info/detail'
import BaseDeleteConfirm from '@/components/base/BaseDeleteConfirm'
import { baseUrl } from '@/config/common'
import { filterAttributes, flatenObject, iconObjectStatus, sortWithNull } from '@/components/utils/common'
import objectValidation from '@/components/mixins/validation/object'
import userInteraction from '@/components/mixins/userInteraction'
import formatters from '@/components/mixins/formatters'
import MapObjectDetail from '@/components/views/account/map/detail/mapInfo/MapObjectDetail'
import report from '@/components/views/account/map/detail/mapPassportDetail/reportTable/report'
import getPath from '@/components/mixins/getPath'
import FancyBox from '@/components/base/FancyBox'
import previewObject from '@/components/mixins/commonForLayouts/previewObject'

export default {
  name: 'PreviewObject',
  components: { MapObjectDetail, BaseBtn, BaseDialog, BaseDeleteConfirm, FancyBox },
  mixins: [detail, objectValidation, userInteraction, formatters, report, getPath, previewObject],
  computed: {
    showAttributes () {// фильтр без значений и показ. в табл.
      return this.objectAttributes.filter(item => item.show_in_table).filter(item => !!this.attributes[item.name])
    },
    funeralReport () {
      return baseUrl + 'object/funeral?id=' + this.detail?.properties?.id
    },
    monumentReport() {
      return baseUrl + 'object/monument?id=' + this.detail?.properties?.id
    }
  },
  methods: {
    async saveAfterImageOpen () {
      if (await this.validate()) {
        await this.save()
        this.$emit('saveAfterImageOpen', this.detail)
      }
    },
    activeEditGeometry () {
      this.isDialog = false
      this.$emit('geometry', { data: this.schema, elementNumber: this.detail.properties.element_number })
    },
    async getPopupButtons () {
      const findGroup = this.objectAttributes?.find((item) => item.eavType.id === 14)
      const hintEdit = findGroup ? findGroup.label : this.$t('base.edit')
      return this.tabs = [
        {
          title: this.isCanInteractionObject ? this.$t('constructor.editAttrs') : this.$t('passport.infoObject'),
          hint: hintEdit,
          icon: this.isCanInteractionObject ? 'mdi-pencil' : 'mdi-information-outline',
          show: true
        },
        {
          title: this.$t('base.images'),
          hint: this.$t('base.images'),
          icon: 'mdi-image-area',
          show: true
        },
        {
          title: this.$t('base.record'),
          hint: this.$t('base.record'),
          icon: 'mdi-file-document-multiple-outline',
          show: this.detail.properties.eav_entity_id === 84
        },
        {
          title: this.$t('base.docs'),
          hint: this.$t('base.docs'),
          icon: 'mdi-file-document-multiple',
          show: true
        },
        {
          title: this.$t('base.historyLog'),
          hint: this.$t('base.historyLog'),
          icon: 'mdi-history',
          show: true
        },
        {
          title: this.$t('base.geometry'),
          hint: this.$t('base.geometry'),
          icon: 'mdi-draw',
          show: this.getCurrentRole !== 'observer'
        }
      ]
    }
  }
}
</script>

<style>
  .leaflet-container a.leaflet-popup-close-button {
    top: 10px;
    right: 10px;
  }
</style>

<style scoped>
  .title-preview {
    word-break: break-word;
    line-height: 1.5rem
  }
  .checkbox {
    min-width: 130px;
  }
  .checkbox /deep/ .v-label {
    font-size: 8.75px !important;
    text-transform: uppercase !important;
  }
  .checkbox /deep/ .v-application--is-ltr .v-input--selection-controls__input {
    margin-right: 0;
  }
</style>
