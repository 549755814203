import BaseSelected from '@/components/structures/map/models/selected/baseSelected'

class PassportSelected extends BaseSelected {
  constructor (props) {
    super(props)
    this.type = 'passport'
  }

  _getUrl () {
    return 'passport'
  }
}

export default PassportSelected
