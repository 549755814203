
export default {
  data () {
    return {
      isDragging: false,
      startClientX: 0,
      endClientX: 0
    }
  },
  methods: {
    startDrag (e) { //для закрытия окна  на "потянуть и бросить"
      this.isDragging = true
      this.startClientY = e.clientY || e.touches[0].clientY
    },
    drag (e) {
      if (!this.isDragging || this.startClientY > 100) return
      this.endClientY = e.clientY || e.touches[0].clientY
      const offset = this.endClientY - this.startClientY
      const swipeItem = document.getElementById(this.idCard)
      swipeItem.style.transform = `translateY(${offset}px)`
    },
    endDrag () {
      const swipeItem = document.getElementById(this.idCard)
      if (!this.isDragging) return
      this.isDragging = false
      if (this.endClientY - this.startClientY >= 150) {
        this.handleClose()
        swipeItem.style.transform = 'inherit'
      } else {
        swipeItem.style.transform = 'translateY(0)'
      }
      this.startClientY = 0
      this.endClientY = 0
    }
  }
}