<template>
  <div>
    <div v-if="detail">
      <v-card
        flat
        :class="isFixHeader ? 'fix-header-in-map' : ''"
      >
        <v-card-text class="pt-2 pb-2">
          <v-row class="align-center">
            <v-col
              cols="8"
              class="pr-1"
            >
              <v-tooltip>
                <template v-slot:activator="{ on }">
                  <div
                    v-on="on"
                    class="pt-1 subtitle-2 align-content-center"
                    :class="label.length > 28 || currentRole ? 'ellipsis-title' : ''"
                  >
                    {{ label }}
                  </div>
                </template>
                {{ label }}
              </v-tooltip>
            </v-col>
            <v-col
              cols="4"
              class="d-flex align-center justify-end pl-0"
            >
              <slot name="tooltips"></slot>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <slot name="tabs"></slot>
      <v-divider></v-divider>
      <slot name="dialog"></slot>
      <!--     ↑ here may be dialog or component(~some content)-->
    </div>
    <BasePreloader v-else/>
  </div>
</template>

<script>
import validationRulesPassport from '@/components/mixins/validation/passport'
import detail from '@/components/mixins/map/info/detail'
import verification from '@/components/mixins/verification'
import BasePreloader from '@/components/base/UI/BasePreloader'
import store from '@/store'

export default {
  name: 'BaseMapInfo',
  mixins: [validationRulesPassport, detail, verification],
  components: { BasePreloader },
  props: {
    label: {
      type: String
    },
    detail: {
      type: Object,
      required: true
    },
    isFixHeader: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentRole() {
      return store.getters['auth/getCurrentRole'] === 'unit'
    }
  }
}
</script>

<style scoped>
.ellipsis-title {
  max-width: 138px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
