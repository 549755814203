import BaseLayers from '@/components/structures/map/models/layers/baseLayers'
import { styles } from '@/components/views/account/map/detail/common/mapStyles'

class PassportLayers extends BaseLayers{
  constructor (props) {
    super(props)
    this.type = 'passport'
  }

  _getTooltip (data) {
    return data.properties ? data.properties.object_name : ''
  }

  _getStyle () {
    return styles.defaultStyle.passport
  }

  _getUrl (props) {
    return super._getUrl(props)// + '?filter[region_id]=' + this.parentId
  }
}

export default PassportLayers
