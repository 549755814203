import BaseSelected from '@/components/structures/map/models/selected/baseSelected'

class ObjectSelected extends BaseSelected {
  constructor (props) {
    super(props)
    this.type = 'object'
  }

  _getDefaultStyle () {
    return this.schemas[this.layer.feature.properties.eav_entity_id].style
  }
}

export default ObjectSelected
