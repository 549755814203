import BaseLayers from '@/components/structures/map/models/layers/baseLayers'
import L from 'leaflet'
import { getRadiusPoint } from '@/components/utils/common'
import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import MapTooltip from '@/components/views/account/map/detail/common/Tooltip'
import {
  deleteOriginObject,
  getCopyObject, getNewObjects,
  readSystemFile, writeCopyObject, writeNewObject,
  writeSystemFile
} from '@/components/utils/mobile/filesystemInteraction'
import store from '@/store'

class ObjectLayers extends BaseLayers {
  static ZoomToRender = 18
  static GeometryTypesRenderOrder = [
    'MultiPolygon',
    'Polygon',
    'LineString',
    'Point'
  ]
  static MapPaneName = 'passportObjects'

  constructor (props) {
    super(props)
    this.type = 'object'
    this.schemas = props.schemas
    this.parentId = props.parentId
    this.dataLoaded = false
    this.on = props.on
    this.edit = props.edit
    this.canvasId = null
  }

  async initLayers (map) {
    if (!this.geoJson.server || !this.geoJson.local) {
      let canvas = null
      map.createPane(ObjectLayers.MapPaneName)
      this.geoJson.server = {}
      this.geoJson.local = {}
      canvas = L.canvas({ pane: ObjectLayers.MapPaneName })
      canvas.options.pmIgnore = true
      this.canvasId = canvas._leaflet_id
      const radius = getRadiusPoint(map._zoom)
      for (let index in ObjectLayers.GeometryTypesRenderOrder) {
        this.geoJson.server[ObjectLayers.GeometryTypesRenderOrder[index]] = {}
        this.geoJson.local[ObjectLayers.GeometryTypesRenderOrder[index]] = {}
        for (let schema in this.schemas) {
          const style = this.schemas[schema].style
          const layer = L.geoJSON(null, {
            renderer: canvas,
            pointToLayer: function (geoJson, latlng) {
              return L.circleMarker(latlng, {
                renderer: canvas,
                radius: radius,
                fillColor: style.color,
                weight: style.weight || 2,
                fillOpacity: 1,
                pmIgnore: true
              })
            },
            style: this._getStyle(schema),
            onEachFeature: this._handleEach()
          })

          this.geoJson.server[ObjectLayers.GeometryTypesRenderOrder[index]][schema] = layer.addTo(map)
          this.geoJson.server[ObjectLayers.GeometryTypesRenderOrder[index]][schema].options.pmIgnore = true

          this.geoJson.local[ObjectLayers.GeometryTypesRenderOrder[index]][schema] = layer.addTo(map)
          this.geoJson.local[ObjectLayers.GeometryTypesRenderOrder[index]][schema].options.pmIgnore = true
        }
      }
    }
    return true
  }

  async loadFromStore (map) {
    map.createPane(ObjectLayers.MapPaneName)
    for (let geometryGroup in this.geoJson.server) {
      for (let schema in this.schemas) {
        this.geoJson.server[geometryGroup][schema].addTo(map)
        this.geoJson.local[geometryGroup][schema].addTo(map)
      }
    }
    return true
  }

  clearLayers () {
    for (let type in this.geoJson.server) {
      if (this.geoJson.server.hasOwnProperty(type)) {
        for (let entityId in this.geoJson.server[type]) {
          if (this.geoJson.server[type][entityId].getLayers().length) {
            this.geoJson.server[type][entityId].clearLayers()
            this.geoJson.server[type][entityId].options.renderer.remove()
          }
        }
      }
    }
    this.dataLoaded = false
  }

  updateLayers (response, data) { // для фильтра
    for (let key in this.geoJson.server) {
      const layers = this.geoJson.server[key][data.filter.eav_entity_id]._layers
      for (let keyLayer in layers) { //удаление слоев по объекту который фильтруем
        if (layers[keyLayer].feature.properties.id) {
          this._removeServerLayer(layers[keyLayer])
        } else {
          this._removeLocalLayer(layers[keyLayer])
        }
      }
    }
    response.forEach((item) => { //а здесь добавление с сервера
      if (item.geometry) {
        this.addLocalLayer(item)
        this._addServerLayer(item, null, false)
      }
    })
  }

  bringToFrontAll () {
  }

  _getStyle (data) {
    return this.schemas[data]?.style
  }

  _getUrl (props) {
    return '/object/map?filter[passport_id]=' + this.parentId + '&filter[verificationScenario]=' + props.scenario
  }

  _canFillLayers (props) {
    return !this.dataLoaded || (_.size(props?.filter) === 1 && props?.filter?.eav_entity_id)
  }

  _processLayersResponse (response, map) {
    ObjectLayers.GeometryTypesRenderOrder.forEach(type => {
      const chunkSize = 2000
      for (let entityId in response.models[type]) {
        const chunks = []
        const objects = response.models[type][entityId]
        for (let i = 0; i < objects.length; i += chunkSize) {
          chunks.push(objects.slice(i, i + chunkSize))
        }
        try { //todo: на время теста
          for (const chunk of chunks) {
            this.geoJson.server[type][entityId].addData({
              type: 'FeatureCollection',
              features: chunk
            })
          }
        } catch (e) {
          console.log(e)
        }
      }
    })
    this.dataLoaded = true
  }

  _getTooltip (data, currentSchema) {
    let Tooltip = Vue.extend(MapTooltip)
    let tooltip = new Tooltip({
      vuetify,
      propsData: {
        data,
        currentSchema
      }
    }).$mount()
    return tooltip.$el
  }

  _removeServerLayer (layer) {
    this.geoJson.server[layer.feature.geometry.type][layer.feature.properties.eav_entity_id].removeLayer(layer._leaflet_id)
  }

  _removeLocalLayer (layer) {
    this.geoJson.local[layer.feature.geometry.type][layer.feature.properties.eav_entity_id].removeLayer(layer._leaflet_id)
  }

  addLocalLayer (data) {
    this.geoJson.local[data.geometry.type][data.properties.eav_entity_id].addData(data)
    const layers = this.geoJson.local[data.geometry.type][data.properties.eav_entity_id].getLayers()
    return layers[layers.length - 1]
  }

  _addServerLayer (data, oldLayer, isNeedLast = true) {
    this.geoJson.server[data.geometry.type][data.properties.eav_entity_id].addData(data)
    const layers = this.geoJson.server[data.geometry.type][data.properties.eav_entity_id].getLayers()
    const result = layers[layers.length - 1]
    this.edit = false
    if (oldLayer?.feature.properties.id) {
      result.options.color = oldLayer.options.color
      result.options.fillColor = oldLayer.options.fillColor
    } else if (isNeedLast) {
      result.options.color = '#000000'
      result.options.fillColor = '#6bfffa'
      store.commit('map/lastSelected', result)
    }
    const radius = getRadiusPoint(result._map._zoom)
    result.setStyle({ radius })
    return result
  }

  async updateSystemLayer (data) { //обновить модель в файлах на устройстве
    if (data?.properties?.id) { // для существующего
      const path = 'map/passport/' + data.properties.passport_id + '/models/' + data.properties.passport_id // модель(отвечает за отображение на карте)
      const copyFile = await getCopyObject(data.properties.passport_id, data.properties.id)
      const fileNew = (await getNewObjects(data.properties.passport_id))?.find((item) => item?.properties?.id === data.properties.id)
      const modelObject = JSON.parse(await readSystemFile(path))
      const isHasModel = _.size(modelObject.models) && modelObject.models.hasOwnProperty(data.geometry.type)
      const changedElement = isHasModel ? modelObject.models[data.geometry.type][data.properties.eav_entity_id]?.findIndex((item) => item?.properties?.id === data?.properties?.id) : false
      const wasPolygon = _.size(modelObject.models) && modelObject.models.hasOwnProperty('Polygon') && data.geometry.type === 'MultiPolygon' && !(changedElement >= 0)
      if (wasPolygon) { //для полигон -> мультиполигон
        const previousElement = modelObject.models['Polygon'][data.properties.eav_entity_id]?.findIndex((item) => item?.properties?.id === data?.properties?.id)
        if (!modelObject.models['MultiPolygon']) { // если нет такого ключа по гео, т.е. Point, Polygon, то надо создать
          modelObject.models['MultiPolygon'] = {}
        }
        if (!modelObject.models['MultiPolygon'][data.properties.eav_entity_id]) { //тоже самое с типом по id (43,32...)
          modelObject.models['MultiPolygon'][data.properties.eav_entity_id] = []
        }
        modelObject.models['Polygon'][data.properties.eav_entity_id].splice(previousElement, 1) //удалить полигон, он теперь мульти
        modelObject.models['MultiPolygon'][data.properties.eav_entity_id].push(data) //добавить мульти полигон
      }

      if (modelObject && (modelObject.models[data.geometry.type][data.properties.eav_entity_id][changedElement] && changedElement >= 0 || wasPolygon)) { //для измененной геометрии
        if (!wasPolygon) modelObject.models[data.geometry.type][data.properties.eav_entity_id][changedElement] = data
        await writeSystemFile(path, modelObject) //добавить в модель
        if (copyFile) { // если есть объект в копии, то поменять его геометрию
          copyFile.geometry = data.geometry
          await writeCopyObject(data.properties.passport_id, data.properties.id, copyFile)//перезаписываем существующую копию
        } else if (fileNew) {
          await writeNewObject(data.properties.passport_id, data, 'geometry') //обновить новый
        } else {// если нет, то создать копию
          await writeCopyObject(data.properties.passport_id, data.properties.id, data)//создаем копию
        }
        await deleteOriginObject(data.properties.passport_id, data.properties.id) //удаляем оригинал, поскольку теперь есть копия
      }
    }
  }

  findByDetailId (data) {
    return this.geoJson.server[data.geometry.type][data.properties.eav_entity_id].getLayers().find(layer => layer.feature.properties.id === data.properties.id)
  }
}

export default ObjectLayers
