import object from '@/components/mixins/map/info/object'
import L from 'leaflet'
import PassportMapModel from '@/components/structures/map/models/model/passportModel'
import user from '@/components/mixins/user'
import { isNeedOfflineMode } from '@/components/utils/mobile/common'
import { verificationScenarioByRole } from '@/components/utils/common'

export const modelUpdateToken = {isUpdateNeeded: false}

export default {
  mixins: [user],
  watch: {
    currentRole: {
      handler (newVal, oldVal) {
        if (oldVal !== newVal) {
          this._initControls()
        }
      },
      deep: true
    }
  },
  methods: {
    async initModel () {
      this.info.isLoading = true
      if (!this.modelStatic || modelUpdateToken.isUpdateNeeded) {
        this.modelStatic = new PassportMapModel({ level: 0, parentId: null })
        await this._initLayers(true)
        this._updateModelStatic()
        modelUpdateToken.isUpdateNeeded = false
      } else {
        await this.modelStatic.loadLayersFromStore(this.map, this.info)
      }
      this._initControls()
      this.info.isLoading = false
      return true
    },
    async handleLayerClick (data) {
      if (!this.isRulerActive) {
        this.info.isLoading = true
        this._toggleLayerPmIgnore(true)
        this._updateInfo(data.detail)
        await this._initLayers()
        this._updateModelStatic()
        this._toggleLayerPmIgnore(false)
        this._initControls()
      }
      if (this.info.type === 'passport' && await isNeedOfflineMode()) {
        await this.setLayers()
        await this.addOfflineTile()
        this.map.removeControl(this.controlLayers.layers)
      }
      this.info.isLoading = false
      return true
    },
    async handleInfoClose (data) {
      this.info.isLoading = true
      if (data?.type === 'passport') {
        this.itemActiveReport = null
        this.info.filter = {}
        this.$store.commit('map/destroyCart')
        this.$store.commit('map/clearSomeObject', 'showObjects')
        this.$store.commit('map/clearSomeObject', 'blockedObjects')
        this.$store.commit('map/clearSomeObject', 'filterObjects')
      }
      if (this.info.type === 'passport') {
        this.$store.commit('map/lastSelected', null)
      }
      this._toggleLayerPmIgnore(true)
      this._updateInfo(this.modelStatic.findDetailByInfo(this.info))
      this._toggleLayerPmIgnore(false)
      this._updateModelStatic()
      this._initControls()
      this.info.isLoading = false
      return true
    },
    async handleInfoSave (data) {
      this.currentActiveEditTool = null
      this.currentActiveDrawTool = null
      if (!this.map.pm.globalDragModeEnabled()) {
        this.modelStatic.findModelByLayer(this.info.layer)._removeCurrentDragMarker()
      }
      await this.modelStatic.updateLayer(this.info.layer, data.detail)
      this.map.closePopup()
      this.info.isLoading = false
      return true
    },
    async saveAfterImageOpen (data) {
      this.info.isLoading = true
      await this.modelStatic.updateLayerAfterImageOpen(this.info.layer, data.detail)
      this.info.isLoading = false
    },
    async changeScenario (scenario) {
      this.info.isLoading = true
      let model = this.modelStatic._getActiveModel(this.info.layer) //нахожу модель(паспорт)
      model.clearChildren() //очистить объекты
      if (model.selected.children) {
        model.selected.children.clearModel()
      }
      this.$set(this.controlLayers, 'overlay', {})
      await this.modelStatic.fillLayersEvented({ //нарисовать объекты
        map: this.map,
        info: this.info,
        filter: undefined,
        scenario
      })

      if (this.zoom >= 18) { //note костыль! Объекты отрисовываются, но надо изменить зум чтобы это увидеть :/
        this.zoom--
      } else {
        this.zoom++
      }
      this.info.isLoading = false
    },
    async handleInfoDelete (data, dataFromObject) {
      this.map.closePopup()
      data.type !== 'object' && await this.modelStatic.deleteLayer(data)
      this._toggleLayerPmIgnore(true)
      this._updateInfo(this.modelStatic.findDetailByInfo())
      this._updateModelStatic()
      this._initControls()
      if (data.type === 'object') {
        await this.modelStatic.deleteLayer(data, dataFromObject)
      }
      //note: два удаления потому что: если оставить только последнее (без условия), то при удалении паспорта его окно не закрывается, а если
      // оставить только верхнее(также без условия), то удаление объекта будет с багами
      this.$store.commit('map/lastSelected', null)
      modelUpdateToken.isUpdateNeeded = true
      return true
    },
    createByCoordinates (geoJson) {
      this.modelStatic.handleDrawCreated(geoJson, this.info)
    },
    handleDrawStart (e) {
      const self = this
      const { workingLayer } = e
      workingLayer.on('pm:vertexadded', (el) => {
        self.onAddVertex(el)
      })
      this.modelStatic.toggleEdit(this.info, true)
    },
    /**
     * @type {import('leaflet').PM.CreateEventHandler}
     */
    handlePmCreate (e) {
      let geoJson = e.layer.toGeoJSON()
      if (this.modelType.entityId) {
        geoJson.properties.eav_entity_id = this.modelType.entityId
      }
      this.modelStatic.handleDrawCreated(geoJson, this.info)
      e.layer.remove()
      this.toggleGeometryAppBar(null)
      this.isGeometry = false
    },
    async handleSearchSelect (data) {
      this.flyTo(await this.modelStatic.findLayerByDetailId({
        info: this.info,
        search: data.detail
      }), 22, data?.options)
      this.navigationIsOpen = false
      return true
    },
    _toggleLayerPmIgnore (status) {
      if (this.info.layer) {
        if (status && this.map.pm.globalEditModeEnabled()) {
          this.map.pm.disableGlobalEditMode()
        }
        if (status && this.map.pm.globalRotateModeEnabled()) {
          this.map.pm.disableGlobalRotateMode()
        }
        this.info.layer.options.pmIgnore = status
        L.PM.reInitLayer(this.info.layer) // note: это возможно устарело
      }
    },
    _updateModelStatic () {
      return this.$store.commit('map/modelStatic', this.modelStatic)
    },
    _updateInfo (info) {
      this.info = { ...this.info, ...info }
    },
    // инициация процесса отрисовки паспортов на карте
    async _initLayers (rootLevel = false) {
      await this.modelStatic.initLayers(this.map, this.info)
      if (this.info.type !== 'object') {
        await this.modelStatic.fillLayersEvented({
          map: this.map,
          info: this.info,
          filter: this.info.filter[this.itemFilter?.eavEntity?.id],
          scenario: verificationScenarioByRole()
        })
      }
      return true
    }
  }
}
