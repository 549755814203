<template>
  <div>
    <DetailView
      v-model="detail"
      :disabled="disabled"
      :attributes="attributes"
      :is-small-height="isSmallHeight"
    />
  </div>
</template>

<script>
import DetailView
  from '@/components/views/account/passport/detail/views/passportDashboard/passportObjectDetail/DetailView'


export default {
  name: 'MapObjectEdit',
  components: { DetailView },
  props: {
    value: Object,
    attributes: Array,
    disabled: Boolean,
    isSmallHeight: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      detail: this.value
    }
  },
  watch: {
    value: {
      handler () {
        this.detail = this.value
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
