<template>
  <BaseDialog
    :value="isDialog"
    @input="$emit('dialog', $event)"
    hide-scroll
    :is-header-spacer="false"
    max-width="100vw"
    is-full-screen
    is-cross-close
    id-card="map-object-detail"
    with-label
    is-swipe-line
    without-sides-padding
    additional-class="dialog-mobile-object"
  >
    <template #content>
      <div class="d-flex flex-row ml-1">
        <v-avatar
          :color="schema.style.color"
          size="30"
        >
          <v-icon
            color="white"
          >{{ schema.icon }}
          </v-icon>
        </v-avatar>
        <p class="font-weight-bold ma-0 pt-0 pb-0 pl-2">{{ schema ? schema.entityName : '—' }} № {{
            detail.properties.element_number ? detail.properties.element_number : '—'
          }}
          <span class="ma-0 d-block font-weight-bold text-caption">{{
              $t('base.updated_at') + ' ' + timestampToDate(detail.properties.updated_at) || ''
            }}</span>
        </p>
      </div>
      <div class="pt-1 pl-1 mb-1 d-flex justify-space-between">
        <BaseBtn
          small
          color="primary"
          :title="$t('base.save')"
          @click="$emit('save')"
          v-if="currentRole !== 'auditor' && isCanInteractionObject"
          :loading="loading"
          :disabled="detail.properties.state === 2"
        />
        <BaseBtn
          small
          color="error"
          :disabled="detail.properties.state === 2"
          @click="$emit('deleteDialog')"
          v-if="isCanDelete"
          :loading="loading"
          class="mr-1"
          icon="mdi-delete"
          is-type-icon
        />
        <BaseBtn
          small
          color="secondary"
          class="mr-1"
          :title="$t('base.restore')"
          @click="$emit('restore')"
          v-show="$can(null, 'editObject') && detail.properties.state === 2 && isCanInteractionObject"
          :loading="loading"
        />
      </div>

      <MapObjectInfo
        :tab-window="tabWindow"
        :value="detail"
        :schema="schema"
        :passport-id="passportId"
        @save="$emit('save')"
        @saveAfterImageOpen="$emit('saveAfterImageOpen', $event)"
        @handleChangeFiles="$emit('handleChangeFiles', $event)"
      />
    </template>
  </BaseDialog>
</template>

<script>
import BaseBtn from '@/components/base/UI/BaseBtn'
import BaseDialog from '@/components/base/mobile/BaseDialog'
import BaseDeleteConfirm from '@/components/base/BaseDeleteConfirm'
import MapObjectInfo from '@/components/views/mobile/map/detail/mapInfo/MapObjectInfo'
import detail from '@/components/mixins/map/info/detail'
import objectValidation from '@/components/mixins/validation/object'
import userInteraction from '@/components/mixins/userInteraction'
import formatters from '@/components/mixins/formatters'
import { isOffline } from '@/components/utils/mobile/common'

export default {
  name: 'MapObjectDetail',
  components: { BaseBtn, BaseDialog, BaseDeleteConfirm, MapObjectInfo },
  mixins: [detail, objectValidation, userInteraction, formatters],
  props: {
    value: Object,
    schema: Object,
    isDialog: Boolean,
    loading: Boolean,
    passportId: Number,
    tabWindow: String // передавать hint
  },
  watch: {
    value: {
      handler () {
        this.detail = this.value
      }, deep: true
    }
  },
  data () {
    return {
      detail: this.value
    }
  },
  computed: {
    isCanDelete () {
      return (this.detail.properties.state === 1 && this.currentRole !== 'auditor' && this.isCanInteractionObject) ||
        (isOffline())
    },
    updatedTime () {
      return this.detail.properties.updated_at ? this.timestampToDate(this.detail.properties.updated_at) : '—'
    },
    labelObject () {
      return (this.schema ? this.schema.entityName : '—') + ' № ' + (this.detail.properties.element_number ? this.detail.properties.element_number : '—')
    }
  }
}
</script>

<style>
</style>
