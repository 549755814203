import {
  changeModel,
  deleteCopyObject,
  deleteFromModel,
  deleteNewObject,
  deleteOriginObject,
  deleteSystemFile,
  getCopyObject, getNewObjects,
  getOriginObject,
  getRemovedObject,
  readSystemFile,
  writeNewObject,
  writeOriginObject,
  writeRemovedObject,
  writeSystemFile
} from '@/components/utils/mobile/filesystemInteraction'
import isOnline from '@/components/mixins/mobile/isOnline'

export default {
  mixins: [isOnline],
  methods: {
    async handleSaveOffline (field = 'properties', noEmit = false) { //Указать поле, которое нужно поменять. Для properties и geometry
      const pathCopy = 'map/passport/' + this.detail.properties.passport_id + '/objects/copies/' + this.detail.properties.id
      const originFile = await getOriginObject(this.detail.properties.passport_id, this.detail.properties.id)

      if (this.detail.properties.id) {
        if (this.detail.properties.state === 2) { //удаленный
          await writeRemovedObject(this.detail.properties.passport_id, this.detail.properties.id, this.detail)
          await deleteOriginObject(this.detail.properties.passport_id, this.detail.properties.id)
          await deleteCopyObject(this.detail.properties.passport_id, this.detail.properties.id)
          this.$emit('save', { detail: this.detail, type: this.type })
        } else if (await getRemovedObject(this.detail.properties.passport_id, this.detail.properties.id)) {
          //Если есть в папке ремувед, то это восстановление -> перемести в копиью(чтобы ушло потом на сервер). Отсюда emit save не отправлять.
          await writeSystemFile(pathCopy, this.detail) //записываю
          await deleteSystemFile('map/passport/' + this.detail.properties.passport_id + '/objects/removed/' + this.detail.properties.id)
        } else {
          //если объект не удаляется и не восстанавливается, значит это обычное изменение.
          const fileCopy = JSON.parse(await readSystemFile(pathCopy))
          const fileNew = await getNewObjects(this.detail.properties.passport_id)
          if (fileCopy) { //есть ли уже копия-файл
            fileCopy[field] = this.detail[field] //перезаписываю в копии отдельное поле
            await writeSystemFile(pathCopy, fileCopy) // перетираю файл-копию
          } else if (fileNew?.find(item => item.properties.id === this.detail.properties.id)) { //если это новый объект нарисованный в оффлайн режиме
            await writeNewObject(this.detail.properties.passport_id, this.detail, 'properties') //создать файл
          } else { // иначе создаю копию
            await writeSystemFile(pathCopy, this.detail) //записываю
          }
          // проверка на оригинальный файл
          if (originFile) { //удалить оригинальный объект(файл) из папки, чтобы отображать из копии(или из новой)
            await deleteOriginObject(this.detail.properties.passport_id, this.detail.properties.id)
          }
          !noEmit && this.$emit('save', { detail: this.detail, type: this.type })
        }
      }

      if (!this.detail.properties.id) { //если нет id, то это новый объект.
        this.$set(this.detail.properties, 'id', Date.now())
        const pathPassport = 'map/passport/' + this.detail.properties.passport_id + '/detail'
        const detailPassport = JSON.parse(await readSystemFile(pathPassport))
        //достаю стили из паспорта по типу объекта, поскольку у нового это поле пустое.
        this.detail.properties.style = detailPassport.properties.passportEntities.find(item => item.eavEntity.id === this.detail.properties.eav_entity_id)?.eavEntity?.style
        await writeNewObject(this.detail.properties.passport_id, this.detail)
        !noEmit && this.$emit('save', { detail: this.detail, type: this.type })
      }
      return true
    },
    async handleDeleteOffline (hard) {
      if (hard === 0) {
        if (this.detail.properties.id) {
          this.$set(this.detail.properties, 'state', 2)
          await changeModel(this.detail)
          const originFile = await getOriginObject(this.detail.properties.passport_id, this.detail.properties.id)
          const copyFile = await getCopyObject(this.detail.properties.passport_id, this.detail.properties.id)
          const newFile = (await getNewObjects(this.detail.properties.passport_id))?.find(item => item.properties.id === this.detail.properties.id)

          if (originFile) {
            await deleteOriginObject(this.detail.properties.passport_id, this.detail.properties.id)
          } else if (newFile) {
            await deleteFromModel(this.detail.properties.passport_id, this.detail) //удаление из карты
            await deleteNewObject(this.detail.properties.passport_id, this.detail) //удаление из файла с новыми объектами
          } else if(copyFile) {
            await deleteCopyObject(this.detail.properties.passport_id, this.detail.properties.id)
          }
          !newFile && await writeRemovedObject(this.detail.properties.passport_id, this.detail.properties.id, this.detail)
        } else {
          await deleteFromModel(this.detail.properties.passport_id, this.detail)
          await deleteNewObject(this.detail.properties.passport_id, this.detail)
        }
        this.$emit('delete', { detail: this.detail, type: this.type, hard })
      }
    }
  }
}